import {
  CaretDownOutlined,
  CaretUpOutlined,
  FileImageOutlined,
  LineChartOutlined,
  PictureOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu as MenuAnt } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from '../../reducers';
import { Role } from '../../role/role';
import { $background, $primary } from '../../styles/Variables';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const { Sider } = Layout;
const { SubMenu: SubMenuAnt } = MenuAnt;

const StyledSlider = styled(Sider)`
  background-color: var(--amz-sidebar-bg);

  .ant-layout-sider-children {
    display: grid;
    align-content: space-between;
    background-color: var(--amz-sidebar-bg);
    overflow: auto;
    width: 180px;
  }
`;

const Menu = styled(MenuAnt)`
  list-style: none;
  padding: 15px 0;
  background-color: var(--amz-sidebar-bg);
  width: auto;

  &.h-60 {
    height: 60%;
  }
`;

const MenuItem = styled(MenuAnt.Item)`
  padding: 20px;
  cursor: pointer;

  span.anticon {
    color: #fff !important;
  }

  .ant-menu-title-content,
  .ant-menu-title-content > a {
    font-weight: 300;
    color: #fff;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.05em;
  }

  &.active span {
    font-weight: 500;
  }

  &&.ant-menu-item-selected {
    background-color: transparent;

    .ant-menu-title-content {
      font-weight: 700;

      a {
        font-weight: inherit;
      }
    }

    ::after {
      content: none;
    }
  }
`;

const SubMenu = styled(SubMenuAnt)`
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.01em;
      color: ${$primary};
    }
  }

  .ant-menu-sub {
    background: ${$background};
  }
`;

const Sidebar = () => {
  const { authUser, loading } = useSelector((state: RootState) => state.auth);

  const renderAdminMenu = () => {
    return (
      <>
        <Menu
          className="h-60"
          defaultSelectedKeys={['videoSubMenu']}
          defaultOpenKeys={['recipeSubMenu', 'programSubMenu', 'videoSubMenu']}
          mode="inline"
          expandIcon={({ isOpen, isSubMenu }) =>
            isSubMenu && isOpen ? (
              <CaretUpOutlined size={12} />
            ) : (
              <CaretDownOutlined size={12} />
            )
          }
        >
          {authUser?.role === Role.ADMIN && (
            <>
              <MenuItem key="user" icon={<UserOutlined />} disabled>
                <Link to="/user">User</Link>
              </MenuItem>
              <MenuItem key="size" icon={<SettingOutlined />} disabled>
                <Link to="/size">Size</Link>
              </MenuItem>
              <MenuItem key="image" icon={<FileImageOutlined />} disabled>
                <Link to="/image">Image</Link>
              </MenuItem>
            </>
          )}
          <MenuItem key="order" icon={<ShoppingCartOutlined />} disabled>
            <Link to="/order">Order</Link>
          </MenuItem>
          <MenuItem key="design" icon={<PictureOutlined />} disabled>
            <Link to="/design">Design</Link>
          </MenuItem>
          <MenuItem key="stats" icon={<LineChartOutlined />} disabled>
            <Link to="/stats">Statistics</Link>
          </MenuItem>
        </Menu>

        {/* <Menu>
          <MenuItem key="settingsTab">
            <Link to="/setting">Setting</Link>
          </MenuItem>
        </Menu> */}
      </>
    );
  };

  const renderMasterAdminMenu = () => {
    return (
      <>
        <Menu
          className="h-60"
          defaultSelectedKeys={['adminAccountsTab']}
          mode="inline"
          expandIcon={({ isOpen, isSubMenu }) =>
            isSubMenu && isOpen ? (
              <CaretUpOutlined size={12} />
            ) : (
              <CaretDownOutlined size={12} />
            )
          }
        >
          <MenuItem key="adminAccountsTab">
            <Link to="/admins">Admin Accounts</Link>
          </MenuItem>
        </Menu>

        <Menu>
          <MenuItem key="settingsTab">
            <Link to="/setting">Setting</Link>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <StyledSlider width={290}>
      {loading && <LoadingSpinner />}
      {/* {authUser?.role === Role.ADMIN && renderAdminMenu()} */}
      {true && renderAdminMenu()}
      {authUser?.role === Role.MASTER_ADMIN && renderMasterAdminMenu()}
    </StyledSlider>
  );
};
export default Sidebar;
