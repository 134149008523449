import { Upload } from 'antd';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import orderApi from '../../apis/order';
import { IDesign } from '../../interfaces/Order';
import { DynamicContainer } from '../../styles/Global';
import CustomInput from '../Form/FormItem/Input/Input';
import BaseModal from './BaseModal/BaseModal';

export interface IProps {
  visible: boolean;
  design?: IDesign;
  onUpdate?: (design: IDesign) => void;
  onCancel?: () => void;
}

const EditDesignModal = (props: IProps) => {
  const { visible, design, onUpdate, onCancel } = props;
  const [sku, setSku] = useState(design?.sku || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSku(design?.sku || '');
  }, [design]);

  const handleUpdate = async () => {
    if (!design?.id) return;

    if (design.sku === sku) return;

    try {
      setLoading(true);
      const res = await orderApi.updateSku(design.id, sku);
      onUpdate?.(res);
      onCancel?.();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledModal
      visibleModal={visible}
      title={'Edit Design'}
      onPrimaryBtnClick={handleUpdate}
      onSecondaryBtnClick={() => {
        onCancel?.();
      }}
      primaryBtn="Save"
      secondaryBtn="Cancel"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <DynamicContainer display="grid" alignitems="center">
        <TextLabel>
          Created: {dayjs.default(design?.createdAt).format('DD MMM YYYY')}
        </TextLabel>
        <TextLabel>SKU</TextLabel>
        <CustomInput
          className="m__b--20"
          value={sku}
          placeholder="Sku is optional"
          onChange={(e) => {
            setSku(e.target.value);
          }}
        />
        <Image src={design?.designUrl} />
      </DynamicContainer>
    </StyledModal>
  );
};

export default EditDesignModal;

const TextLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: left;
`;

const StyledModal = styled(BaseModal)`
  &.ant-modal {
    width: 640px !important;
  }

  .ant-modal-footer {
  }
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list-item-container {
    width: 160px;
    height: 160px;
  }
`;

const Label = styled.p`
  text-align: left;
  margin-bottom: 5px;
`;

const SwitchLabel = styled.p`
  text-align: left;
  margin: 0 10px 0 0;
  font-weight: 600;
`;

const ItemWrapper = styled.div`
  height: 100px;
  margin: auto;

  img {
    max-height: 98px;
    max-width: 300px;
    /* width: auto; */
    object-fit: contain;
    margin: auto;
  }
`;

const ListDesign = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  min-height: 60px;
  max-height: 320px;
  overflow: auto;
`;

const DesignItemContainer = styled.div<{ $selected: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  border: 2px solid #484839;
  display: grid;
  align-items: center;
  justify-content: center;

  ${({ $selected }) =>
    $selected &&
    `
    border-color: #095cef;
  `}
`;

const DesignItem = styled.img`
  max-width: 92px;
  max-height: 92px;
  object-fit: contain;
`;

const Image = styled.img`
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  margin: auto;
`;
