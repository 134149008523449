import { LocalStorageKeys } from "../constants/storage";

export const setAuthTokens = (accessToken: string, refreshToken?: string) => {
  localStorage.setItem("accessToken", accessToken);

  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export const removeAuthTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem(LocalStorageKeys.USER_LOGGED_IN);
  localStorage.setItem(LocalStorageKeys.USER_LOGGED_OUT, "1");
  localStorage.removeItem(LocalStorageKeys.NO_AD);
};

export const getAuthTokens = () => {
  return {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };
};
