export const $primary = "#6c7ae0";
export const $secondary = "#fafafa";
export const $danger = "#FF5858";
export const $background = "#E1E9FA";
export const $white = "#fff";
export const $black = "#303033";
export const $border = "#E3E4EB";
export const $purple = "#7C73C5";
export const $lightgrey = "#E3E4EB";
export const $lightpurple = "#E2DEFA";
export const $lightwhite = "#fafafa";

export const $fontSizeXxsmall = "8px";
export const $fontSizeXsmall = "10px";
export const $fontSizeSmall = "12px";
export const $fontSizeNormal = "14px";
export const $fontSizeMedium = "16px";
export const $fontSizeLarge = "18px";
export const $fontSizeXLarge = "20px";
export const $fontSizeXXLarge = "22px";

export const $maxMargin = 100;
export const $maxPadding = 200;
export const $maxBorderRadius = 30;

export const $mobile = "(max-width: 767px)";
export const $desktop = "(min-width: 1024px)";
