declare global {
  interface Window {
    env: any;
  }
}

const config = {
  API_HOST:
    process.env.REACT_APP_API_HOST ||
    (typeof window !== "undefined" && window.env.REACT_APP_API_HOST),
};

export default config;
