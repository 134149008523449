export enum RESPONSE_ERROR {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export const ExceptionInternalCode = {
  INVALID_REGISTED_EMAIL: 'INVALID_REGISTED_EMAIL',
  INVALID_EMAIL_VERIFICATION_LINK: 'INVALID_EMAIL_VERIFICATION_LINK',
  EXPIRED_EMAIL_VERIFICATION_LINK: 'EXPIRED_EMAIL_VERIFICATION_LINK',
  EXPIRED_EMAIL_PASSWORD_RECOVERY_LINK: 'EXPIRED_EMAIL_PASSWORD_RECOVERY_LINK',
  INVALID_EMAIL_VERIFICATION_LINK_WHEN_CHANGED_EMAIL:
    'INVALID_EMAIL_VERIFICATION_LINK_WHEN_CHANGED_EMAIL',
};
