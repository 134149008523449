import { Spin, Upload, UploadFile, UploadProps, message } from 'antd';
import * as dayjs from 'dayjs';
import { useState } from 'react';
import orderApi from '../../apis/order';
import { PageContainer } from '../../styles/Global';
import { SpinContainer } from '../Order/Order.styled';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const ImageProcess = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [spinLoading, setSpinLoading] = useState(false);

  const handleOpenCsvFile: UploadProps['onChange'] = (file: any) => {
    setFileList(
      file?.fileList.length
        ? [
            {
              ...file.fileList[0],
              status:
                file.fileList[0].status === 'error'
                  ? 'done'
                  : file.fileList[0].status,
            },
          ]
        : file?.fileList
    );
  };

  const str2bytes = (str) => {
    var bytes = new Uint8Array(str.length);
    for (var i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  };

  const hanldeUploadFile = async () => {
    try {
      setSpinLoading(true);

      const formData = new FormData();
      formData.append('dataFile', fileList[0]?.originFileObj || '');

      const res: any = await orderApi.processImages(formData);

      const url = window.URL.createObjectURL(
        new Blob([res], { type: 'application/zip' })
      );
      const tmpLink = document.createElement('a');
      tmpLink.href = url;
      const fileName = `${dayjs.default(new Date()).format('DD-MM-YYYY')}.zip`;
      tmpLink.setAttribute('download', fileName);
      document.body.appendChild(tmpLink);
      tmpLink.click();
      tmpLink.remove();
    } catch (error) {
      console.error(error);
      message.error('Sorry an error occurred while processing your file!');
    } finally {
      setSpinLoading(false);
      setFileList([]);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Process Images" />
      <div className="m__t--20" />
      <Upload
        name="file"
        onChange={handleOpenCsvFile}
        multiple={false}
        fileList={fileList}
        accept=".csv"
      >
        {fileList.length < 1 && (
          <PrimaryButton className="font-weight--500 m__r--10" key="3">
            Open CSV File
          </PrimaryButton>
        )}
      </Upload>
      {spinLoading && (
        <SpinContainer>
          <Spin size="large" />
        </SpinContainer>
      )}
      {fileList.length > 0 && (
        <PrimaryButton
          className="font-weight--500 m__r--10"
          key="4"
          onClick={hanldeUploadFile}
        >
          Process
        </PrimaryButton>
      )}
    </PageContainer>
  );
};

export default ImageProcess;
