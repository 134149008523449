export enum AuthActionTypeEnum {
  SET_CURRENT_USER = "SET_CURRENT_USER",
  SET_AUTHENTICATING = "SET_AUTHENTICATING",
  SET_USER_SCHEDULE_SETTING = "SET_USER_SCHEDULE_SETTING",
  USER_LOGOUT = "USER_LOGOUT",
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",

  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",

  GET_ME_REQUEST = "GET_ME_REQUEST",
  GET_ME_SUCCESS = "GET_ME_SUCCESS",
  GET_ME_FAILURE = "GET_ME_FAILURE",
}

export enum UserActionTypeEnum {}

type ActionType = AuthActionTypeEnum | UserActionTypeEnum;

export interface IAction {
  type: ActionType;
  payload: Record<string, any>;
}
