import type { TextAreaProps } from 'antd/lib/input/TextArea';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { $primary } from '../../styles/Variables';

// import { $primary } from '~styles/Variables';

export const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  border: 1px solid ${$primary};
  padding: 10px 16px;
`;

const CustomTextArea = (props: TextAreaProps) => {
  return <StyledTextArea {...props}></StyledTextArea>;
};
export default CustomTextArea;
