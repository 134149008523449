import { Form, message } from 'antd';
import userApi from '../../apis/user';
import { IUser } from '../../interfaces/UserModel';
import CustomForm from '../Form/Form';
import CustomInput from '../Form/FormItem/Input/Input';
import BaseModal from './BaseModal/BaseModal';

export interface SearchDesignProps {
  visible: boolean;
  user?: IUser;
  onCancel?: () => void;
}

const ChangePasswordModal = (props: SearchDesignProps) => {
  const { visible, onCancel, user } = props;

  const [form] = Form.useForm<{ password: string }>();

  const changePassword = async () => {
    try {
      if (!user?.id) {
        return;
      }
      const res = await userApi.updatePassword(
        user.id as string,
        form.getFieldValue('password')
      );
      if (res) {
        message.success('Update user password success!');
      }
      onCancel?.();
    } catch (err) {
      console.error(err);
      message.error('Update user password failed!');
    }
  };

  return (
    <BaseModal
      visibleModal={visible}
      title="Change Password"
      onSecondaryBtnClick={() => {
        onCancel?.();
      }}
      onPrimaryBtnClick={() => form.submit()}
      primaryBtn="Change"
      secondaryBtn="Cancel"
    >
      <h4>User: {user?.username}</h4>
      <CustomForm
        form={form}
        layout="vertical"
        scrollToFirstError
        requiredMark="optional"
        onFinish={changePassword}
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              type: 'string',
              message:
                'Please input a password with at least 8 characters including numbers and alphabeltical characters',
            },
          ]}
        >
          <CustomInput placeholder="Password" type="password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <CustomInput placeholder="Confirm password" type="password" />
        </Form.Item>
      </CustomForm>
    </BaseModal>
  );
};

export default ChangePasswordModal;
