import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Prop {
  title?: string;
  showBackButton?: boolean;
  addButton?: React.ReactNode;
  addButtonText?: string;
  customBlock?: React.ReactNode;
  onBack?: () => void;
}

const PageHeader = ({
  title,
  showBackButton = false,
  addButton = false,
  addButtonText = '',
  customBlock,
  onBack,
}: Prop) => {
  const navigate = useNavigate();

  return (
    <PageHeaderContainer $showAddButton={!!addButton || !!customBlock}>
      {showBackButton && (
        <ArrowLeftOutlined size={20} onClick={() => navigate(-1)} />
      )}
      {title && <PageTitle>{title}</PageTitle>}
      {!!addButton && addButton}
      {!!customBlock && customBlock}
    </PageHeaderContainer>
  );
};

export default PageHeader;

const PageHeaderContainer = styled.div<{ $showAddButton: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;

  ${({ $showAddButton }) => $showAddButton && `justify-content: space-between;`}
`;

const PageTitle = styled.h2`
  font-size: 24px;
  letter-spacing: 0.06rem;
  line-height: 130%;
`;
