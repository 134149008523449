import { UserLoginRequestDto, UserLoginResponseDto } from '../interfaces/Auth';
import { IUser } from '../interfaces/UserModel';
import Client from './client';

class AuthenticationApi {
  public static classInstance: AuthenticationApi;

  static get instance() {
    if (!this.classInstance) {
      this.classInstance = new AuthenticationApi();
    }

    return this.classInstance;
  }

  public login(body: UserLoginRequestDto): Promise<UserLoginResponseDto> {
    return Client.post(`/auth/login`, body);
  }

  public logout(refreshToken: string): Promise<void> {
    return Client.post(`/auth/logout?refresh_token=${refreshToken}`);
  }

  public getAccessToken(refreshToken: string): Promise<UserLoginResponseDto> {
    return Client.get(`/auth/access_token?refresh_token=${refreshToken}`);
  }

  public getMe(): Promise<IUser> {
    return Client.get(`/user/me`);
  }
}

export default AuthenticationApi.instance;
