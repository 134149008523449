import { SET_LOADING_FULL_SCREEN, SET_LOADING_STATE } from '../actions/loading';

const initialState = {
  isPageLoading: false,
  isLoadingFullScreen: false,
};

const loading = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_STATE: {
      return {
        ...state,
        isPageLoading: action.isPageLoading,
      };
    }
    case SET_LOADING_FULL_SCREEN: {
      return {
        ...state,
        isLoadingFullScreen: action.isLoadingFullScreen,
      };
    }
    default:
      return state;
  }
};

export default loading;
