import type { FormProps } from "antd";
import { Form } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";
import {
  $background,
  $fontSizeNormal,
  $fontSizeXsmall,
  $primary,
} from "../../styles/Variables";

const CustomFormStyled = styled(Form)`
  .ant-form-item {
    .ant-form-item-label {
      label {
        font-size: ${$fontSizeNormal};
        font-weight: 300;
        line-height: 16px;
        color: ${$primary};

        .ant-form-item-optional {
          border-radius: 4px;
          margin-left: 8px;
          padding: 4px;
          height: fit-content;
          font-size: ${$fontSizeXsmall};
          font-weight: 500;
          background: ${$background};
          color: ${$primary};
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`;

export const CustomForm = (props: FormProps) => {
  return (
    <CustomFormStyled {...props}>
      {props?.children as ReactNode}
    </CustomFormStyled>
  );
};

export default CustomForm;
