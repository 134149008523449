import { InputNumber } from "antd";
import styled from "styled-components";
import { $primary } from "../../../../styles/Variables";

const CustomInputNumberStyle = styled(InputNumber)`
  border-radius: 8px;
  border: 1px solid ${$primary};
  padding: 10px 16px;

  .ant-input-number-handler-wrap {
    display: none;
  }

  input {
    height: 22px;
    padding: 0;
  }
`;

const CustomInputNumber = (props: any) => {
  return <CustomInputNumberStyle type="number" {...props} />;
};

export default CustomInputNumber;
