import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Form from 'antd/lib/form';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import orderApi from '../../apis/order';
import {
  IDesign,
  IOrder,
  IOrderItem,
  ISize,
  RequestStatus,
} from '../../interfaces/Order';
import {
  ButtonGroupContainer,
  FlexContainer,
  PageContainer,
} from '../../styles/Global';
import CustomForm from '../Form/Form';
import CustomInput from '../Form/FormItem/Input/Input';
import SearchDesignModal from '../Modal/SearchDesignModal';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {
  CheckoutDraftRow,
  CreateOrderItemWrapper,
  ImageContainer,
  OrderItemWrapper,
  StyledInputNumber,
  StyledSelect,
} from './Order.styled';

import { Input } from 'antd';
import { Country, OrderStatus } from '../../constants/order';
import { RootState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { Role } from '../../role/role';
import RequestResendModal from '../Modal/RequestResendModal';
import CustomTextArea from '../TextArea/TextArea';

const { Search } = Input;

export interface SizeForm extends ISize {
  publishedDateISO?: Date;
  startedAtISO?: Date;
  endedAtISO?: Date;
  bannerImage?: any;
  horizontalCoverImage?: any;
  horizontalCoverV2Image?: any;
  playlistLinksItems?: {
    playlistLinks: string;
  }[];
}

const OrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [order, setOrder] = useState<IOrder>({
    orderId: '',
    address: '',
    trackingReference: '',
    country: Country.US,
    designs: [
      {
        quantity: 1,
        itemCost: 0,
      },
    ],
  });
  const [sizes, setSizes] = useState<ISize[]>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [listDesign, setListDesign] = useState<string[]>([]);
  const [currentDesignIdx, setCurrentDesignIdx] = useState(0);
  const [country, setCountry] = useState(Country.US);
  const [status, setStatus] = useState(OrderStatus.PENDING);
  const { currentUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );
  const isAdmin = currentUser?.role === Role.ADMIN;
  const [total, setTotal] = useState(0);
  const [visibleRequestModal, setVisibleRequestModal] = useState(false);

  const isFormDisabled = useMemo(() => {
    return !isAdmin && !!id && status !== OrderStatus.PENDING;
  }, [isAdmin, id, status]);

  useEffect(() => {
    fetchSize();
    if (!!id) {
      fetchOrder(id);
    }
  }, []);

  const calculateTotal = () => {
    const shippingFee = country === Country.US ? 0.99 : 1.99;

    const designs: IOrderItem[] = form.getFieldValue('designs');

    if (!designs) {
      return shippingFee;
    }

    return designs.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.itemCost || 0),
      shippingFee
    );
  };

  const fetchOrder = async (id: string) => {
    if (!id) return;

    try {
      const result = await orderApi.getOrderDetail(id);

      const {
        orderId,
        address,
        trackingReference,
        country,
        designs,
        orderDesigns,
        totalCost,
        status,
        resendRequest,
      } = result;
      let newListDesign: string[] = [];
      const tmp = orderDesigns?.map((d) => {
        if (d?.design?.designUrl) {
          newListDesign?.push(d.design.designUrl);
        }
        return {
          itemCost: d?.itemCost || 0,
          sizeId: d?.size?.id,
          designId: d?.design?.id,
          quantity: d?.quantity,
          id: d?.id,
          product: d?.size?.product,
        } as IOrderItem;
      });

      setListDesign(newListDesign);
      setOrder({
        orderId,
        address,
        trackingReference,
        country,
        designs: tmp,
        totalCost,
        resendRequest,
        status,
        id,
      });

      setCountry(country as Country);
      setStatus(status as OrderStatus);
      form.setFieldsValue({
        orderId,
        address,
        trackingReference,
        country,
        designs: tmp,
        status,
      });
      const tmpTotal = calculateTotal();
      setTotal(tmpTotal);
    } catch (error) {
      navigate(-1);
      console.error(error);
    }
  };

  const fetchSize = async () => {
    try {
      const result = await orderApi.getSizes({
        page: 1,
        perPage: 100,
        all: true,
      });
      setSizes(result.items);
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrder = async () => {
    if (!id) {
      return;
    }

    try {
      setLoading(true);
      const { orderId, address, trackingReference, country, designs, status } =
        form.getFieldsValue(true);
      const payload: IOrder = {
        orderId,
        address,
        trackingReference,
        country,
        designs,
        status,
      };

      await orderApi.updateOrder(id, payload);
      setStatus(status);
      setCountry(country);
      message.success('Update order success!');
    } catch (e) {
      console.error(e);
      message.error('Update order failed');
    } finally {
      setLoading(false);
    }
  };

  const deleteOrder = async () => {
    if (!id) {
      return;
    }

    try {
      setLoading(true);
      await orderApi.deleteOrder([id]);
      message.success('Delete order success!');
      navigate(-1);
    } catch (e) {
      message.error('Delete order fail!');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const createOrder = async () => {
    try {
      setLoading(true);
      const payload: IOrder = form.getFieldsValue(true);

      const error = payload.designs?.some((d) => !d.designId || !d.sizeId);

      if (error) {
        message.error('Please input all required field');
        return;
      }
      await orderApi.createOrder(payload);
      message.success('Create order success!');
      navigate(-1);
    } catch (e) {
      console.error(e);
      message.error('Create order failed');
    } finally {
      setLoading(false);
    }
  };

  const handleTotal = (_, values) => {
    const orderItems = [...values.designs];
    values?.designs.forEach((fieldGroup: IOrderItem, index) => {
      if (fieldGroup && fieldGroup?.quantity && fieldGroup?.sizeId) {
        const idx = sizes?.findIndex((s) => s?.id === fieldGroup?.sizeId);
        if (idx < 0) {
          return;
        }
        fieldGroup.itemCost = fieldGroup?.quantity * sizes[idx]?.price;
        orderItems.splice(index, 1, fieldGroup);
        form.setFieldsValue({ designs: orderItems });
        const tmpTotal = calculateTotal();
        setTotal(tmpTotal);
      }
    });
  };

  const getResendRequestBtnColor = () => {
    switch (order?.resendRequest?.status) {
      case RequestStatus.PENDING:
        return '#1d39c4';
      case RequestStatus.APPROVED:
        return '#389e0d';
      case RequestStatus.REJECTED:
        return '#dc143c';
    }
  };

  return (
    <>
      <PageContainer>
        <RequestResendModal
          visible={visibleRequestModal}
          onCancel={() => setVisibleRequestModal(false)}
          order={order}
          onConfirm={(request) =>
            setOrder((order) => ({ ...order, resendRequest: request }))
          }
          onApprove={(request) => {
            fetchOrder(id || '');
          }}
          onReject={(request) => {
            setOrder((order) => ({ ...order, resendRequest: request }));
          }}
          onCreateNew={(request) => {
            setVisibleRequestModal(false);
            setOrder((order) => ({ ...order, resendRequest: undefined }));
            setVisibleRequestModal(true);
          }}
        />
        <FlexContainer className="w-70">
          <PageHeader title="Order Detail" showBackButton />
          {!!order.resendRequest ||
          (currentUser?.role === Role.SELLER &&
            order.status === OrderStatus.SHIPPED) ? (
            <PrimaryButton
              className="font-weight--500 w-30"
              onClick={() => {
                setVisibleRequestModal(true);
              }}
              color={getResendRequestBtnColor()}
            >
              Resend Request{' '}
              {!!order.resendRequest
                ? `(${order.resendRequest?.status})`
                : null}
            </PrimaryButton>
          ) : null}
        </FlexContainer>

        <div className="w-70">
          <CustomForm
            form={form}
            initialValues={order}
            layout="vertical"
            requiredMark="optional"
            onFinish={() => (!!id ? updateOrder() : createOrder())}
            disabled={isFormDisabled}
            onValuesChange={handleTotal}
            scrollToFirstError={true}
          >
            {!!id && isAdmin && (
              <Form.Item name="status" label="Status">
                <StyledSelect
                  className="w-100"
                  value={status}
                  onChange={(newValue: any) => {
                    form.setFieldValue('status', newValue);
                  }}
                  options={[
                    { label: 'Pending', value: OrderStatus.PENDING },
                    { label: 'Process', value: OrderStatus.PROCESSING },
                    { label: 'Shipped', value: OrderStatus.SHIPPED },
                    { label: 'ReShipped', value: OrderStatus.RESHIPPED },
                    { label: 'Cancel', value: OrderStatus.CANCELLED },
                  ]}
                />
              </Form.Item>
            )}
            <Form.Item
              name="orderId"
              label="Order Id"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please order id',
                },
              ]}
            >
              <CustomInput placeholder="Order Id" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input address',
                },
              ]}
            >
              <CustomTextArea
                autoSize={{ minRows: 3, maxRows: 4 }}
                placeholder="Desktop description"
                maxLength={1000}
              />
            </Form.Item>
            {(!id || (!!id && isAdmin)) && (
              <Form.Item name="trackingReference" label="Tracking (Optional)">
                <CustomInput placeholder="Tracking" />
              </Form.Item>
            )}
            <Form.Item name="country" label="Country">
              <StyledSelect
                className="w-100"
                placeholder="Choose country"
                value={country}
                onChange={(newValue: any) => {
                  form.setFieldValue('country', newValue);
                  setCountry(newValue);
                }}
                options={[
                  { label: 'US', value: Country.US },
                  { label: 'CANADA', value: Country.CANADA },
                ]}
              />
            </Form.Item>
            <Form.List name="designs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <OrderItemWrapper key={name}>
                      <ImageContainer>
                        {!!listDesign[name] && <img src={listDesign[name]} />}
                      </ImageContainer>
                      <div>
                        <CreateOrderItemWrapper>
                          <Form.Item hidden name="id" label="Id">
                            <CustomInput placeholder="Id" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'sizeId']}
                            rules={[{ required: true, message: '' }]}
                            label="Size"
                          >
                            <StyledSelect
                              className="w-100"
                              placeholder="Pick a size"
                              options={sizes?.map((s) => ({
                                label: s.size,
                                value: s.id,
                              }))}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                            label="Quantity"
                          >
                            <StyledInputNumber
                              min={1}
                              max={1000000}
                              placeholder="Quantity"
                              step={1}
                              className="w-100"
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'product']}
                            label="Product"
                          >
                            <StyledInputNumber className="w-100" disabled />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'itemCost']}
                            label="Cost"
                          >
                            <StyledInputNumber className="w-100" disabled />
                          </Form.Item>
                          <PrimaryButton
                            onClick={() => {
                              setCurrentDesignIdx(name);
                              setVisibleModal(true);
                            }}
                            className="m__b--14"
                          >
                            Pick Design
                          </PrimaryButton>
                        </CreateOrderItemWrapper>
                      </div>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name);
                          const tmpDesign = cloneDeep(listDesign);
                          tmpDesign.splice(key, 1);
                          setListDesign(tmpDesign);
                        }}
                      />
                    </OrderItemWrapper>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({ quantity: 1, itemCost: 0 });
                        const tmpDesign = cloneDeep(listDesign);
                        tmpDesign.push('');
                        setListDesign(tmpDesign);
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <CheckoutDraftRow>
              <label>{country?.toUpperCase()} shipping fee</label>
              <p>${country === Country.US ? 0.99 : 1.99}</p>
            </CheckoutDraftRow>
            <CheckoutDraftRow>
              <label>Total</label>
              <p>${total.toLocaleString('en-US')}</p>
            </CheckoutDraftRow>
            <Form.Item>
              <ButtonGroupContainer>
                {!!id && status === OrderStatus.PENDING && (
                  <PrimaryButton
                    disabled={loading}
                    style={{ background: '#e7d4d4' }}
                    className="text-danger"
                    htmlType="button"
                    onClick={deleteOrder}
                  >
                    <span className="font-size--14">Remove</span>
                  </PrimaryButton>
                )}
                <PrimaryButton htmlType="submit">Save</PrimaryButton>
              </ButtonGroupContainer>
            </Form.Item>
          </CustomForm>
        </div>
      </PageContainer>
      <SearchDesignModal
        visible={visibleModal}
        sizes={sizes}
        onCancel={() => setVisibleModal(false)}
        onSelect={(design: IDesign) => {
          const currentFormValue: IOrderItem[] = form.getFieldValue('designs');
          currentFormValue[currentDesignIdx] = {
            ...currentFormValue[currentDesignIdx],
            designId: design.id || '',
          };
          form.setFieldValue('designs', currentFormValue);
          const tmp = cloneDeep(listDesign);
          if (!design.designUrl) {
            return;
          }

          tmp[currentDesignIdx] = design.designUrl;
          setListDesign(tmp);
        }}
      />

      {/* {showCancelConfirmationModal && (
        <BaseModal
          primaryBtn="Confirm"
          secondaryBtn="Cancel"
          // onPrimaryBtnClick={() => onCancel(true)}
          onSecondaryBtnClick={() => setShowCancelConfirmationModal(false)}
        >
          Quit editing? Changes you made so far will not be saved
        </BaseModal>
      )} */}
    </>
  );
};
export default OrderDetail;
