import { Select } from "antd";
import styled from "styled-components";
import { $background, $danger, $primary } from "../../../../styles/Variables";

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    padding: 1px 3px;
    min-height: 44px;

    .ant-select-selection-item {
      background: ${$background};
      border: none;
      border-radius: 8px;
      padding: 10px 16px;
      color: ${$primary};
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      height: 36px;

      .anticon {
        color: ${$primary};
        font-size: 9px;
      }
    }
  }
`;

const StyledPrimarySelect = styled(StyledSelect)`
  .ant-select-selector {
    border: 1px solid ${$primary} !important;
  }
`;

const StyledErrorSelect = styled(StyledSelect)`
  .ant-select-selector {
    border: 1px solid ${$danger} !important;
  }
`;

const CustomSelect = (props: any) => {
  const { error, ...attrs } = props;
  if (error) {
    return (
      <StyledErrorSelect
        getPopupContainer={(triggerNode: HTMLElement) =>
          (triggerNode?.parentNode as HTMLElement) || document.body
        }
        {...attrs}
      ></StyledErrorSelect>
    );
  } else {
    return (
      <StyledPrimarySelect
        getPopupContainer={(triggerNode: HTMLElement) =>
          (triggerNode?.parentNode as HTMLElement) || document.body
        }
        {...attrs}
      ></StyledPrimarySelect>
    );
  }
};
export default CustomSelect;
