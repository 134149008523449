import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { login } from '../../actions/auth';
import { IAuthState } from '../../reducers/auth';

const LoginContainer = styled.div`
  height: fit-content;
`;

const Title = styled.p`
  font-size: 34px;
  font-weight: 300;
`;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(
    (state: RootStateOrAny) => state.auth as IAuthState
  );

  const [showErr, setShowErr] = useState(false);

  const onFinish = async (values: any) => {
    setShowErr(false);

    dispatch(
      login(
        values,
        () => {},
        () => {
          setShowErr(true);
        }
      )
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <Wrapper className="bg--primary">
      <LoginContainer className="bg--white d-flex flex-direction--column text-align--center align-items--center border-radius--20 p--40">
        <Title className="text-primary m__b--16">HD STICKER</Title>
        <FormContainer>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            autoComplete="off"
          >
            {showErr && (
              <Alert
                message="Username or password is wrong"
                type="error"
                className="m__b--16"
                showIcon
              />
            )}

            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input username!' }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      </LoginContainer>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  margin: 10px;
`;
