import Modal, { ModalProps } from 'antd/lib/modal';
import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../styles/styled';

interface IProps extends ModalProps {
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
  visible?: boolean;
  okText?: string;
  cancelText?: string;
  onOk?(): void;
  okButtonProps?: any;
  onCancel?(): void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  confirmLoading?: boolean;
  width?: number;
  centered?: boolean;
  maskClosable?: boolean;
  closable?: boolean;
  zIndex?: number;
  haveAds?: boolean;
  holdOnCurrentPosition?: boolean;
  bgColor?: string;
}

const ModalConfirm = (props: IProps) => {
  const { getContainer } = props;

  return (
    <StyledModal
      title=""
      closable={props?.closable}
      maskClosable={props?.maskClosable}
      visible={props?.visible}
      onOk={props?.onOk}
      okButtonProps={props?.okButtonProps}
      onCancel={props?.onCancel}
      okText={props?.okText}
      cancelText={props?.cancelText}
      footer={props?.footer}
      className={props?.className}
      confirmLoading={props?.confirmLoading}
      width={props?.width}
      centered={props?.centered ?? true}
      zIndex={props?.zIndex || 2147483647}
      bgColor={props.bgColor}
      getContainer={getContainer}
    >
      <Headline>{props?.title}</Headline>
      <Description>{props?.content || props?.children}</Description>
    </StyledModal>
  );
};

export default ModalConfirm;

ModalConfirm.defaultProps = {
  maskClosable: true,
  closable: true,
};

const StyledModal = styled(Modal)<{ width?: number; bgColor?: string }>`
  && {
    min-width: unset;
    height: auto;
    padding: 0 24px var(--sticky-ads-height-mobile);
    ${({ width }) => !!width && `width: ${width}px !important;`}

    ${mediaQuery.md`
      padding: 0 5px;
    `}

    .ant-modal-content {
      height: auto;
      min-height: unset;
      border-radius: 8px;
      ${({ bgColor }) => !!bgColor && `background-color: ${bgColor};`}
    }

    .ant-modal-body {
      padding: 40px 20px;
      text-align: center;
    }

    .ant-modal-footer {
      flex-direction: column-reverse;

      & > .ant-btn {
        margin-top: 8px;

        :last-child {
          margin-top: 0;
        }

        :not(.ant-btn-primary):hover {
          border-color: var(--color-primary);
          color: var(--color-primary);
        }
      }

      .ant-btn {
        &:last-child {
          margin: 0;
        }
      }

      ${mediaQuery.md`
        flex-direction: row;

        & > .ant-btn {
        margin-right: 10px;
        margin-top: 0;

        :last-child {
          margin-right: 0;
          margin-top: 0;
        }
      }
      `}
    }

    ${mediaQuery.md`
    .ant-modal-body {
      padding: 40px 70px;
    }
    `}
  }
`;

export const Headline = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
`;
