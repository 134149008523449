import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { getTokenLocalStorage } from './actions/auth';
import { parseJwt } from './helpers/jwt';
import { AuthActionTypeEnum } from './interfaces/Reducer.interface';
import { IAuthState } from './reducers/auth';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticating } = useSelector(
    (state: RootStateOrAny) => state.auth as IAuthState
  );

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const { accessToken } = getTokenLocalStorage();
      if (!accessToken) {
        navigate('/login');
        return;
      }

      // dispatch(
      //   getMe(() => {
      //     const { sub, role } = parseJwt(accessToken);
      //     unstable_batchedUpdates(() => {
      //       dispatch({
      //         type: AuthActionTypeEnum.LOGIN_SUCCESS,
      //         payload: {
      //           authUser: {
      //             id: sub,
      //             role,
      //           },
      //         },
      //       });
      //     });
      //     return;
      //   })
      // );
      // const res = await authApi.getAccessToken(refreshToken);
      // setTokenLocalStorage(res.accessToken, res.refreshToken);
      if (!!accessToken) {
        const { sub, role } = parseJwt(accessToken);
        unstable_batchedUpdates(() => {
          dispatch({
            type: AuthActionTypeEnum.LOGIN_SUCCESS,
            payload: {
              authUser: {
                id: sub,
                role,
              },
            },
          });
        });
        // return;
      }
      dispatch({
        type: AuthActionTypeEnum.SET_AUTHENTICATING,
        payload: {
          authenticating: false,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: AuthActionTypeEnum.LOGOUT_REQUEST,
      });
      navigate('/login');
    }
  };

  // if (!isAuthenticating) {
  //   return <LoadingSpinner />;
  // }

  return <Outlet />;
};
export default App;
