import type { InputProps } from 'antd';
import { Input } from 'antd';
import styled from 'styled-components';
import { $primary, $white } from '../../../../styles/Variables';

export const StyledInput = styled(Input)<InputProps>`
  border-radius: 8px;
  border: 1px solid ${$primary};
  padding: 10px 16px;

  &:focus {
    box-shadow: none;
  }

  ${({ addonAfter }) =>
    !!addonAfter &&
    `
    padding: 0;

    .ant-input-group {
      & > input {
        border: 0;
        border-radius: 8px;
        padding: 8px 16px;
      }

      & > .ant-input-group-addon {
        border: none;
        background-color: ${$white};
        border-radius: 0 8px 8px 0;
        border-left: 1px solid ${$primary};
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.01em;
        color: ${$primary};
        padding: 9px;
      }
    }
  `}
`;

export const StyledInputPassword = styled(Input.Password)`
  border-radius: 8px;
  border: 1px solid ${$primary};
  padding: 10px 16px;
`;

const CustomInput = (props: InputProps) => {
  const { type } = props;
  if (type === 'password') {
    return <StyledInputPassword {...props}></StyledInputPassword>;
  }
  return <StyledInput {...props}></StyledInput>;
};
export default CustomInput;
