export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_HEADER_LOADING_STATE = 'SET_HEADER_LOADING_STATE';
export const SET_LOADING_FULL_SCREEN = 'SET_LOADING_FULL_SCREEN';

export const setLoadingState = (isPageLoading: boolean) => ({
  type: SET_LOADING_STATE,
  isPageLoading,
});

export const setLoadingFullScreen = (isLoadingFullScreen: boolean) => ({
  type: SET_LOADING_FULL_SCREEN,
  isLoadingFullScreen,
});
