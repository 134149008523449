import { Dropdown, Input, InputNumber } from 'antd';
import styled from 'styled-components';
import { CustomTable } from '../../styles/Global';
import { $background, $black, $primary } from '../../styles/Variables';
import CustomSelect from '../Form/FormItem/Select/Select';

const { Search } = Input;

export const ActionsStyled = styled.div`
  border-radius: 8px;
  background-color: ${$background};
  padding: 20px;
  width: 164px;
  position: sticky;
  top: 90px;
`;

export const ActionsSyncStyled = styled(ActionsStyled)`
  top: 200px;

  .ant-btn-loading {
    &::before {
      display: none;
    }
  }
`;

export const CreateOrderItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 120px) auto;
  column-gap: 10px;
  margin-bottom: 14px;
  align-items: end;

  .ant-form-item:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const PickDesignWrapper = styled.div``;

export const StyledSelect = styled(CustomSelect)`
  .ant-select-selector {
    border-radius: 8px !important;
    padding: 1px 3px;
    min-height: 44px;

    .ant-select-selection-item {
      border: none;
      border-radius: 8px;
      padding: 14px 0;
      color: ${$black};
      background-color: transparent;
      font-size: 14px;
      line-height: 14px;
      height: 44px;

      .anticon {
        color: ${$primary};
        font-size: 9px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    margin: auto;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 42px !important;
  }

  .ant-select-selection-placeholder {
    margin: auto;
  }

  &.ant-select-multiple .ant-select-selection-overflow {
    max-width: 100px !important;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.ant-select-multiple
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item {
    height: auto !important;
    padding: 0 !important;
  }
`;

export const OrderItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr auto;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

export const ImageContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #6c7ae0;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 98px;
    max-height: 98px;
    border-radius: 16px;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  border: 1px solid #6c7ae0;
  height: 44px;

  input {
    height: 42px;
    padding: 10px 16px;
  }
`;

export const ActionText = styled.span<{ $disabled: boolean }>`
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    `
    user-events: none;
    cursor: not-allowed;
  `}

  &.process {
    color: #0086ea;
  }

  &.finish {
    color: #e26664;
  }
`;

export const CheckoutDraftRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  label {
    font-weight: 600;
    font-size: 18px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const SearchFilterContainer = styled.div<{ $isAdmin?: boolean }>`
  display: grid;
  gap: 20px;

  ${({ $isAdmin }) =>
    $isAdmin
      ? `grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;`
      : `grid-template-columns: 2fr 2fr 1fr 1fr 1fr;`}
`;

export const StyledSearch = styled(Search)`
  /* border: 1px solid #6c7ae0; */

  &.ant-input-group-wrapper {
    height: 44px;
  }

  .ant-btn.ant-btn-icon-only {
    width: 42px;
    height: 42px;
  }

  .ant-btn-default {
    border-color: #6c7ae0;
  }

  input.ant-input {
    height: 42px;
    border: 1px solid #6c7ae0;
  }
`;

export const NestedTable = styled(CustomTable)`
  &.ant-table-wrapper .ant-table-thead > tr > th {
    padding: 4px 10px;
  }

  .ant-table-tbody {
    background-color: #fff;

    > tr > td {
      background-color: #fff !important;
    }
  }
`;

export const NestedTableImage = styled.img`
  max-height: 50px;
  max-width: 100px;
  object-fit: contain;
`;

export const StatusTag = styled.div<{
  $pending?: boolean;
  $process?: boolean;
  $shipped?: boolean;
  $cancel?: boolean;
  $reshipped?: boolean;
  $rejected?: boolean;
  $fontSize?: number;
}>`
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  text-transform: capitalize;
  padding: 6px 8px;
  min-width: 80px;
  text-align: center;
  border-radius: 8px;

  ${({ $fontSize }) =>
    $fontSize &&
    `
      font-size: ${$fontSize}px;
  `}

  ${({ $process }) =>
    $process &&
    `
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  `}

  ${({ $shipped }) =>
    $shipped &&
    `
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
  `}

  ${({ $reshipped }) =>
    $reshipped &&
    `
    color: #950d9e;
    background: #ddbdd9;
    border-color: #e98feb;
  `}

  ${({ $cancel }) =>
    $cancel &&
    `
    color: #183f3b;
    background: #cdcb7a;
    border-color: #323b09;
  `}

  ${({ $rejected }) =>
    $rejected &&
    `
    color: #ffffff;
    background: #dc143c;
    border-color: #960018;
  `}
`;

export const CustomSize = styled.span<{
  $2in?: boolean;
  $3in?: boolean;
  $4in?: boolean;
  $5in?: boolean;
  $6in?: boolean;
  $card?: boolean;
}>`
  border-radius: 20px;
  padding: 3px 12px;
  min-width: 40px;
  text-align: center;
  max-width: 60px;
  font-size: 15px;
  font-weight: 600;

  ${({ $2in }) => $2in && `background-color: #e7eaec`}
  ${({ $3in }) => $3in && `background-color: #e6e6e7`}
  ${({ $4in }) => $4in && `background-color: #bee1f7`}
  ${({ $5in }) => $5in && `background-color: #d5edbc`}
  ${({ $6in }) => $6in && `background-color: #e5d0f2`}
  ${({ $card }) => $card && `background-color: #fee5a0`}
`;

export const StyledCountry = styled.p<{ $cad: boolean }>`
  font-weight: 500;

  ${({ $cad }) => $cad && `color: #e59a0f;`}
`;

export const StyledQuantity = styled.span<{ $moreThan1: boolean }>`
  font-weight: 600;
  font-size: 15px;

  ${({ $moreThan1 }) =>
    $moreThan1 &&
    `
    color: #ececd5;
    background-color: #549016;
    border-radius: 20px;
    padding: 3px 12px;
  `}
`;

export const DesignWrapper = styled.div<{ $totalItems: number }>`
  display: grid;
  /* grid-template-columns: repeat(4, 40px) 20px; */
  align-items: end;
  gap: 4px;

  ${({ $totalItems }) => {
    if ($totalItems > 0 && $totalItems <= 3) {
      return `
        grid-template-columns: repeat(${$totalItems}, 60px);

        img {
          max-width: 60px;
          max-height: 60px;
        }
      `;
    }

    if ($totalItems >= 4) {
      return `
        grid-template-columns: repeat(4, 40px) 20px;

        img {
          max-width: 40px;
          max-height: 40px;
        }
      `;
    }
  }}

  img {
    object-fit: contain;
  }
`;

export const Mask = styled.div``;

export const ThreeDot = styled.p`
  font-weight: 600;
`;

export const BulkActionButton = styled(Dropdown)<{ $disabled: boolean }>`
  font-size: 14px;
  height: 44px;
  padding: 13px 15px;
  border-radius: 6px;
  background: ${({ $disabled }) => ($disabled ? '#9c9fb7' : '#6c7ae0')};
  border: none;
  color: white;
  margin: auto;
  font-weight: 500;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const SpinContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  z-index: 10;
  top: 0;
  background-color: #cfd5db;
  opacity: 0.5;
  left: 0;
`;

export const SellerDateWrapper = styled.div`
  display: grid;
  gap: 8px;
  align-items: center;
`;
