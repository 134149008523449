import { EditOutlined } from '@ant-design/icons';
import { Checkbox, message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import orderApi from '../../apis/order';
import { IDesign, SortOption } from '../../interfaces/Order';
import { PaginationParamsResponse } from '../../interfaces/PaginationParamsResponse';
import { PageContainer } from '../../styles/Global';
import { mediaQuery } from '../../styles/styled';
import DesignsEditor from '../DesignsEditor/DesignsEditor';
import DesignsUploader from '../DesignsUploader/DesignsUploader';
import ConfirmModal from '../Modal/ConfirmModal';
import EditDesignModal from '../Modal/EditDesignModal';
import { StyledSearch, StyledSelect } from '../Order/Order.styled';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const START_PAGE = 1;
const PER_PAGE = 60;

const DesignList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(START_PAGE);
  const [loading, setLoading] = useState(false);
  const [itemsPagination, setItemsPagintion] = useState<
    PaginationParamsResponse<IDesign>
  >({
    items: [],
    page: START_PAGE,
    perPage: PER_PAGE,
    total: 0,
    pageCount: 0,
  });
  const [visibleUpdateModal, setVisibleUpdateModal] = useState(false);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState<IDesign>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sortFieldValue, setSortFieldValue] = useState('byDate');
  const [sortOrderValue, setSortOrderValue] = useState(SortOption.DESC);
  const [search, setSearch] = useState('');
  const [displayUploadModal, setDisplayUploadModal] = useState(false);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [selectedDesigns, setSelectedDesigns] = useState<Array<IDesign>>([]);

  useEffect(() => {
    loadData();
  }, [currentPage]);

  useEffect(() => {
    setItemsPagintion({
      items: [],
      page: START_PAGE,
      perPage: PER_PAGE,
      total: 0,
      pageCount: 0,
    });
    loadData();
  }, [sortOrderValue, search]);

  const loadData = async (
    page = itemsPagination?.page,
    perPage = itemsPagination?.perPage
  ) => {
    try {
      setLoading(true);
      let query = {
        sku: search,
      };
      if (sortFieldValue && sortOrderValue) {
        query[sortFieldValue] = sortOrderValue;
      }
      const res = await orderApi.getDesigns(
        {
          page: currentPage,
          perPage: currentPage === START_PAGE ? PER_PAGE : 40,
        },
        query
      );
      if (!!res) {
        const { page, pageCount, items: data } = res;
        unstable_batchedUpdates(() => {
          setItemsPagintion({
            ...itemsPagination,
            items:
              currentPage === START_PAGE
                ? data
                : [...itemsPagination.items, ...data],
          });
          setHasMore(page < pageCount);
        });
      }
    } catch (error) {
      setItemsPagintion({
        items: [],
        page,
        perPage,
        total: 0,
        pageCount: 0,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    if (selectedDesigns.length < 1) {
      return;
    }

    try {
      setDeleteLoading(true);
      const designs = selectedDesigns.map(
        (design) => design.id
      ) as Array<string>;
      await orderApi.deleteDesign(designs);

      await loadData();
      setSelectedDesigns([]);
      setVisibleConfirmModal(false);
      message.success('Delete design success!');
    } catch (e) {
      console.error(e);
      message.error('Delete design fail!');
    } finally {
      setDeleteLoading(false);
    }
  };

  const onStateChanges = async (page: number, perPage: number) => {
    navigate(`${location.pathname}?page=${page}&perPage=${perPage}`, {
      replace: true,
    });
    loadData(page, perPage);
  };

  const onPageChange = (page: number, pageSize: number) => {
    onStateChanges(page, pageSize);
  };

  const fetchMoreData = () => {
    if (hasMore && itemsPagination.items.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderSkeleton = () => {
    return (
      <ListImage>
        {range(10).map((n) => (
          <div key={n}>
            <Skeleton borderRadius={10} />
          </div>
        ))}
      </ListImage>
    );
  };

  const isChecked = (design: IDesign) => {
    return !!selectedDesigns.find((des) => des.id === design.id);
  };

  const onSelectDesign = (design: IDesign) => {
    if (!isChecked(design)) {
      setSelectedDesigns((des) => [...des, design]);
      setSelectedDesign(undefined);
    } else {
      const updatedSelectedDesigns = selectedDesigns.filter(
        (des) => des.id !== design.id
      );
      setSelectedDesigns(updatedSelectedDesigns);
    }
  };

  return (
    <PageContainer id="scrollable">
      <HeaderContainer className="m__b--10">
        <PageHeader title="Manage Design" />
        <div>
          {selectedDesigns.length > 0 && (
            <>
              <PrimaryButton
                onClick={() => {
                  setVisibleConfirmModal(true);
                }}
                ghost
              >
                Delete Designs
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setDisplayEditModal(true);
                }}
                className="m__l--10"
              >
                Edit Designs
              </PrimaryButton>
            </>
          )}
          <PrimaryButton
            onClick={() => {
              setDisplayUploadModal(true);
            }}
            className="m__l--10"
          >
            Upload Designs
          </PrimaryButton>
        </div>
      </HeaderContainer>
      <DesignsUploader
        visible={displayUploadModal}
        onCancel={() => {
          setDisplayUploadModal(false);
        }}
        onUploaded={() => {
          loadData();
        }}
      />
      <DesignsEditor
        visible={displayEditModal}
        designs={selectedDesigns}
        onCancel={() => {
          setDisplayEditModal(false);
        }}
        onUpdate={async () => {
          setSelectedDesigns([]);
          setDisplayEditModal(false);
          await loadData();
        }}
      />
      <FilterContainer>
        <StyledSearch
          placeholder="Type to search..."
          onSearch={(val) => setSearch(val)}
        />
        <StyledSelect
          className="w-100"
          value={sortOrderValue}
          onChange={(val: any) => {
            setSortOrderValue(val);
          }}
          options={[
            { label: 'Newest', value: SortOption.DESC },
            { label: 'Latest', value: SortOption.ASC },
          ]}
        />
      </FilterContainer>
      {loading && currentPage === START_PAGE ? (
        renderSkeleton()
      ) : itemsPagination.items.length ? (
        <InfiniteScroll
          dataLength={itemsPagination.items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<div className="m__t--16">{renderSkeleton()}</div>}
          scrollableTarget="scrollable"
        >
          <ListImage>
            {itemsPagination.items.map((u, index) => (
              <ImageWrapper
                key={`${u.id}-${index}`}
                onClick={() => {
                  onSelectDesign(u);
                }}
              >
                <img src={u.designUrl} alt="image" />
                <ImageCheckbox
                  onClick={() => {
                    onSelectDesign(u);
                  }}
                  checked={isChecked(u)}
                />
                <ActionContainer>
                  <IconContainer
                    onClick={() => {
                      setSelectedIndex(index);
                      setSelectedDesign(u);
                      setVisibleUpdateModal(true);
                    }}
                  >
                    <EditOutlined />
                  </IconContainer>
                </ActionContainer>
              </ImageWrapper>
            ))}
          </ListImage>
        </InfiniteScroll>
      ) : null}
      <EditDesignModal
        design={selectedDesign}
        visible={visibleUpdateModal}
        onCancel={() => setVisibleUpdateModal(false)}
        onUpdate={(design: IDesign) => {
          const tmp = cloneDeep(itemsPagination);
          if (!selectedIndex) return;

          tmp.items[selectedIndex] = design;
          setItemsPagintion({
            ...itemsPagination,
            items: tmp.items,
          });
        }}
      />
      <ConfirmModal
        title="Are you sure?"
        content="You are deleting a design, this action cannot be undone."
        visible={visibleConfirmModal}
        onOk={onDelete}
        onCancel={() => setVisibleConfirmModal(false)}
      />
    </PageContainer>
  );
};

export default DesignList;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
`;

const ListImage = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 8px;
`;

const ActionContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  z-index: 10;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  padding: 4px 8px;
  border-radius: 12px;
  box-shadow: brown;
  box-shadow: 0px 3px #f3f3ea;
`;

const IconContainer = styled.div``;

const ImageWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #86a2a3;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    max-height: 150px;
    border-radius: 8px;
  }

  ${mediaQuery.size1615`
    
  `}

  &:hover {
    ${ActionContainer} {
      display: flex;
      cursor: pointer;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImageCheckbox = styled(Checkbox)`
  position: absolute;
  top: -10px;
  right: -5px;
`;
