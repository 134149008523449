import { Form, Image, Input, InputRef, Table, message } from 'antd';
import { FormInstance } from 'antd/lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import orderApi from '../../apis/order';
import { IDesign } from '../../interfaces/Order';
import { StyledModal } from '../Modal/SearchDesignModal';

interface DesignsEditorProps {
  designs: Array<IDesign>;
  visible: boolean;
  onCancel?: () => void;
  onUpdate?: () => void;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (record?.[dataIndex]) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [record?.[dataIndex]]);

  const save = async () => {
    try {
      const values = await form.validateFields();

      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    return (
      <td {...restProps}>
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      </td>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DesignsEditor = (props: DesignsEditorProps) => {
  const { designs, onCancel, onUpdate, visible } = props;

  const [dataSource, setDataSource] = useState<IDesign[]>(designs);
  const [loading, setLoading] = useState(false);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: 'design',
      dataIndex: 'design',
      width: '30%',
      render: (_: any, design: IDesign) => {
        return (
          <Image
            src={design.designUrl}
            alt={design.sku}
            preview={false}
            width={120}
            height={120}
          />
        );
      },
    },
    {
      title: 'sku',
      dataIndex: 'sku',
      editable: true,
    },
  ];

  const handleSave = (row: IDesign) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IDesign) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    setDataSource(designs);
  }, [designs]);

  const handleUpdate = async (): Promise<void> => {
    try {
      setLoading(true);
      await orderApi.updateMultipleDesignsSku(dataSource);
      onUpdate?.();
      message.success('Update designs sku successfully!');
    } catch (err) {
      console.error(err);
      message.error('Update designs sku failed!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledModal
      visibleModal={visible}
      title={'Edit Designs Sku'}
      // footer={null}
      onPrimaryBtnClick={handleUpdate}
      onSecondaryBtnClick={() => {
        onCancel?.();
      }}
      primaryBtn="Update"
      secondaryBtn="Cancel"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </StyledModal>
  );
};

export default DesignsEditor;
