import { Table, Tag } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { mediaQuery } from '../styles/styled';
import BorderStyle from './Border';
import DisplayStyle from './Display';
import GlobalFontStyle from './Font';
import SpacingStyle from './Spacing';
import {
  $background,
  $border,
  $fontSizeNormal,
  $lightwhite,
  $primary,
  $secondary,
} from './Variables';
const { CheckableTag } = Tag;

const GlobalStyle = createGlobalStyle`
  ${GlobalFontStyle}
  ${SpacingStyle}
  ${DisplayStyle}
  ${BorderStyle}
  html.locked-root-scroll,
  html.locked-root-scroll body {
    /* want to fix the height to the window height */
    height: calc(var(--window-height) - 1px);

    /* want to block all overflowing content */
    overflow: hidden;

    /* want to exclude padding from the height */
    box-sizing: border-box;
  }

  html {
    @media only screen and (max-width: 767px) {
      overscroll-behavior: none;
    }
  }

  body {
    margin: 0;
    font-family: Roboto, Manrope, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-primary);
    height: auto;

    h1, h2, h3, h4, h5, h6, dd, p {
      margin-bottom: 0;
      margin-top: 0;
    }

    #nprogress .bar {
      background: #1a73e8;
      height: 3px;
    }
  }

  * {
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  #root {
    position: relative;
    min-height: 100vh;
  }

  button {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  strong {
    font-weight: 600 !important;
  }

  a {
    &:hover {
      color: unset;
    }
  }

  .ant-progress-success-bg, .ant-progress-bg {
    background-color: var(--color-primary);
  }

  .ant-progress-status-success .ant-progress-bg {
    background-color: var(--color-primary);
  }

  .ant-progress-status-success .ant-progress-text {
    color: var(--color-primary);
  }

  .infinite-scroll-component {
    overflow: unset !important;
  }

  .cursor {
    &--not-allowed {
      cursor: not-allowed !important;
    }

    &--pointer {
      cursor: pointer !important;
    }
  }

  .d__flex {
    display: flex;
  }

  .scroll-disabled {
    overflow: hidden !important;
  }

  .hidden-dropdown {
    .timezone-dropdown {
      display: none !important;
    }
  }

  .hidden-scrollbar {
    ::-webkit-scrollbar {
      height: 0 !important;
    }
  }

  @mixin for-mobile {
    @media only screen and (max-width: 767px) {
    @content;
    }
  }

  @mixin for-desktop-only {
    @media only screen and (min-width: 1024px) {
    @content;
    }
  }

  .ant-tooltip {
    padding: 0;
    cursor: pointer;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      padding: 11px 16px;
      background-color: #303033;
      box-shadow: 0px 4px 18px rgb(184 191 205 / 24%);
      border-radius: 6px;
      color: #fff;
      font-size: ${$fontSizeNormal};
      line-height: 20px;
    }
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1000 !important;
  }

  .ant-modal {
    &.quit-program-modal {
      ${mediaQuery.lg`
        max-width: 512px;
      `}
    }
  }

  .ant-switch {
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &-checked:focus {
      box-shadow: none;
    }

    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      display: none !important;
    }
  }

  .switch-notification.ant-switch {
    &-checked {
      background: var(--color-purple);
    }
  }

  .switch-status.ant-switch {
    &-checked {
      background: #51B541;
    }
  }

  .switch-purple.ant-switch {
    &-checked {
      background: var(--color-purple);
    }
  }

  .message {
    width: 100vw;
    top: 92px;
    position: absolute;
    padding: 15px;

    .ant-message-notice-content {
      box-shadow: none;
      background-color: transparent;
      padding: 0;

      .anticon {
        display: none;
      }

      span {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &--success {
      background-color: #EBFFF3;

      span {
        color: var(--color-primary);
      }
    }

    &--error {
      background-color: #FCF1F2;

      span {
        color: var(--color-mistake);
      }
    }

    ${mediaQuery.md`
      top: 58px;
      .ant-message-notice-content {
        span {
          font-weight: 600;
          font-size: 16px;
        }
      }
  `};
  }

  .ant-form-item-explain {
    font-size: 12px;
    margin-left: 14px;
  }

  .ant-notification {
    z-index: 2147483647 !important;
  }

  @media screen and (max-width: 767px) {
    .hide-for-small-only {
      display: none !important;
    }
  }

  @media screen and (max-width: 0em), screen and (min-width: 767px) {
    .show-for-small-only {
      display: none !important;
    }
  }

  @media print, screen and (min-width: 40em) {
    .hide-for-medium {
      display: none !important;
    }
  }

  @media screen and (max-width: 39.9375em) {
    .show-for-medium {
      display: none !important;
    }
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hide-for-medium-only {
      display: none !important;
    }
  }

  @media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
    .show-for-medium-only {
      display: none !important;
    }
  }

  @media print, screen and (min-width: 64em) {
    .hide-for-large {
      display: none !important;
    }
  }

  @media screen and (max-width: 63.9375em) {
    .show-for-large {
      display: none !important;
    }
  }

  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .hide-for-large-only {
      display: none !important;
    }
  }

  @media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
    .show-for-large-only {
      display: none !important;
    }
  }

  .ant-image-preview-body {
    .ant-image-preview-operations {
      background-color: rgba(0, 0, 0, 0.5);

      li.ant-image-preview-operations-operation:nth-child(n+4) {
        display: none;
      }
    }

    .ant-image-preview-img-wrapper {
      top: 46px;
    }
  }

  // Popover
  .weight_chart__popover {
    padding: 0;
    z-index: 2147483646;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      width: 290px;
      height: 185px;
      background-color: var(--color-white);
      border: 1px solid ${$border};
      box-shadow: 0px 4.05715px 19.2715px rgba(126, 139, 173, 0.15);
      border-radius: 6px;
      padding: 27px 24px 20px;
      display: flex;
      align-items: center;

      .ant-popover-inner-content {
        padding: 0;
      }
    }

    ${mediaQuery.md`
      z-index: 99;
    `};
  }

  .photo__popover {
    padding: 16px 0 0 0;
    z-index: 2147483646;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      background-color: transparent;
      border: none;
      box-shadow: none;
      margin-right: -10px;

      .ant-popover-inner-content {
        padding: 0;
      }

      ${mediaQuery.md`
        margin-right: 0 !important;
      `}

      ${mediaQuery.xl`
        margin-right: -125px !important;
      `}
    }

    ${mediaQuery.md`
      z-index: 99;
    `};
  }

  .notification__popover {
    z-index: 2147483646;
    padding-top: 7px;

    @media screen and (max-width: 767px) {
      padding-top: 0;
      position: sticky;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 12px;
      margin-right: 0;
      margin-top: -1px;

      .ant-popover-inner-content {
        padding: 0;
      }

      ${mediaQuery.md`
        margin-top: 8px;
        border-radius: 8px;
      `}

    }
  }

  .conversation__popover {
    z-index: 2147483646;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 12px;

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }

  .emoji__popover {
    z-index: 2147483646;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 12px;

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }

  // lazysizes
  // default src to prevent broken image symbol
  // Ref: https://github.com/aFarkas/lazysizes#broken-image-symbol
  // img.lazyload:not([src]) {
  //   visibility: hidden;
  // }

  // fade image in after load
  .lazyload,
  .lazyloading {
    opacity: 0;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }

  .chloeting-mention-chatbox__control,
  .chloeting-mention-editbox__control {
    max-height: 100px;
    overflow: auto;
  }

  .chloeting-mention-chatbox,
  .chloeting-mention-chatbox__suggestions,
  .chloeting-mention-editbox,
  .chloeting-mention-editbox__suggestions {
    width: 100%;
  }

  .chloeting-mention-chatbox__input {
    margin: 0 !important;
  }

  .chloeting-mention-chatbox__input,
  .chloeting-mention-editbox__input {
    font-variant: inherit;
  }

  .chloeting-mention-chatbox__suggestions__item--focused,
  .chloeting-mention-editbox__suggestions__item--focused {
    background-color: #e3e4eb;
  }

  .chloeting-mention-chatbox__highlighter .mention-user,
  .chloeting-mention-editbox__highlighter .mention-user {
    color: #4088ff;
    position: relative;
    left: -1px;
    top: -1px;
    z-index: 1;
    visibility: visible !important;
    font-weight: normal !important;
  }

  .chloeting-mention-editbox__highlighter .mention-user {
    left: 1px;
    top: 1px;
  }

  .chloeting-mention-editbox--multiLine {
    border-radius: 12px;
    background-color: #f4f4f6;

    .chloeting-mention-editbox__highlighter {
      padding: 6px 16px;

      ${mediaQuery.md`
        padding: 10px 16px;
      `}
    }
  }
`;

export default GlobalStyle;

export const PageTitle = styled.h1`
  font-weight: 400;
  font-size: 44px;
  line-height: 1.33;
  ${mediaQuery.md`
    font-size: 56px;
  `}
`;
export const Container = styled.div`
  display: none;

  ${mediaQuery.md`
    display: block;
    width: 100%;
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 var(--layout-gutter);
  `}
`;
export const Loading = styled.div<{ $dimmer?: boolean }>`
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  ${({ $dimmer }) =>
    $dimmer &&
    `
    background-color: rgba(0, 0, 0, 0.2);
  `}

  .ant-spin {
    top: 50%;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const Select = styled.select`
  color: var(--color-secondary);
  border: 1px solid #c4c4c4;
  min-width: 60px;
  border-radius: 5px;
  padding: 5px;
`;
export const SectionTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;
export const FlexContainer = styled.div<{
  $direction?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $direction }) =>
    $direction &&
    `
      flex-direction: ${$direction};
  `}
`;
export const DefaultContainer = styled.div`
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--layout-gutter-mobile);

  ${mediaQuery.lg`
    padding: 0 var(--layout-gutter);
  `}

  ${mediaQuery.size1240`
    padding: 0;
  `}
`;
export const EmptyPage = styled.div`
  background-color: var(--color-background);
  margin: 50px auto;
  padding: 16px;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
  max-width: var(--container-width);
  border-radius: 6px;
  text-align: center;

  &.tab-skeleton span[aria-live='polite'] {
    width: 95%;
  }

  ${mediaQuery.sm`
    margin-top: 50px;
  `};
`;

export const PanigationWrapper = styled.div<{
  $isDisabledNextArrow?: boolean;
  $isNewVersion?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isNewVersion }) =>
    $isNewVersion &&
    `
  justify-content: flex-start;
  `}

  .ant-pagination-item {
    border: none;
    background-color: transparent;
    color: var(--color-secondary);
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #f9f9fa;
    border-radius: 50%;

    & a {
      color: var(--color-primary);
    }
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-item:hover a {
    color: rgba(0, 0, 0, 0.85);
  }

  ${({ $isDisabledNextArrow }) =>
    $isDisabledNextArrow &&
    `
    .ant-pagination-next {
      button {
        cursor: not-allowed;
        color: var(--color-primary-disabled);
      }
    &:hover {
        button {
            cursor: not-allowed;
            color: var(--color-primary-disabled);
        }
      }
    }
  `}

  .ant-pagination-next {
    cursor: not-allowed !important;
    color: var(--color-primary-disabled) !important;
  }
`;

export const SearchWrapper = styled.div<{
  $isHaveValue: boolean;
  $maxWidth?: number;
  $minWidth?: number;
}>`
  display: flex;
  align-items: center;
  display: flex;
  padding: 6px 9px 6px 14px;
  border-radius: 4px;
  border: var(--border-primary);
  ${({ $maxWidth }) => !!$maxWidth && `max-width: ${$maxWidth}px;`}
  ${({ $minWidth }) => !!$minWidth && `min-width: ${$minWidth}px;`}

  > input {
    border: none;
    padding: 0;
    flex: 1;
    margin-right: 4px;
    color: var(--color-secondary);
    background-color: transparent;

    &:focus-visible {
      outline: none;
    }
  }

  :focus,
  :hover {
    border-color: var(--color-primary);
    background-color: var(--color-white);

    > [class^=\"icon-\"],
    > [class*=\"icon-\"] {
      color: var(--color-primary);
    }
  }

  ${({ $isHaveValue }) =>
    $isHaveValue &&
    `
  border-color: var(--color-primary);

  > input {
    color: var(--color-primary);
  }

  > [class^=\"icon-\"],
    > [class*=\"icon-\"] {
      color: var(--color-primary);
    }
  `}
`;

export const SelectOptionStyle = createGlobalStyle`
  .filter-select__option {
    color: var(--color-primary);
    padding: 7px 20px;

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: ${$lightwhite};
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${$lightwhite};
    }
  }
`;

export const DropdownClass = createGlobalStyle`
  .ant-picker-dropdown, .ant-dropdown, .ant-tooltip {
    z-index: 99999999999 !important;
  }
`;

interface IDynamicContainer {
  display?: 'flex' | 'block' | 'grid' | 'inline-grid' | 'inline-flex';
  justifyContent?:
    | 'space-evenly'
    | 'space-around'
    | 'space-between'
    | 'flex-end'
    | 'flex-start'
    | 'end'
    | 'start'
    | 'center'
    | 'left'
    | 'right';
  alignitems?:
    | 'baseline'
    | 'center'
    | 'start'
    | 'end'
    | 'self-end'
    | 'self-start'
    | 'flex-start'
    | 'flex-end';
}

export const DynamicContainer = styled.div<IDynamicContainer>`
  ${({ display }) =>
    display &&
    `
      display: ${display};
  `}

  ${({ justifyContent }) =>
    justifyContent &&
    `
      justify-content: ${justifyContent};
  `}

  ${({ alignitems }) =>
    alignitems &&
    `
      align-items: ${alignitems};
  `}
`;

export const Spacing = styled.div<{ $height?: number }>`
  height: ${({ $height = 30 }) => $height}px;
`;

export const SelectDropdownInsideModal = createGlobalStyle`
  .ant-select-dropdown {
    z-index: 2147483646 !important;
  }
`;

export const UserInfoBadgesWrapper = styled.div<{ $badgeMaxHeight?: number }>`
  ${({ $badgeMaxHeight }) =>
    $badgeMaxHeight &&
    `
      div {
        max-height: ${$badgeMaxHeight}px;
        width: fit-content !important;

        svg {
          width: auto !important;
        }
      }
    `}
`;

export const ViewAllButtonStyled = styled.a`
  color: var(--color-primary);
  font-weight: 500;
  user-select: none;

  ${mediaQuery.md`
    padding: 6px 15px;
    border: 1px solid var(--color-primary);
    border-radius: 52px;
  `}
`;

export const PageContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow: scroll;
  position: relative;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const CustomTable = styled(Table)`
  td.ant-table-cell {
    /* padding: 6px 12px !important; */
  }
  .ant-table-tbody > tr > td {
    /* padding: 8px 12px; */
  }

  .ant-table-cell {
    :has(.nested-table) {
      border-bottom: 1px solid #000;
    }
  }

  &.ant-table-wrapper .ant-table-thead > tr > th,
  &.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 4px 12px;
    background-color: #fafafa;
  }

  &.ant-table-wrapper tr.ant-table-expanded-row > td {
    padding: 16px 12px;
    border-bottom: 1px solid #a2a1a1;
    /* background-color: #fff !important; */
  }

  /* .ant-table-row {
    &.approved {
      > td.ant-table-cell {
        color: #dbdbdb;
      }
    }
  }

  .ant-table-thead > tr > th {
    color: ${$secondary};
    background: ${$primary};
  }

  .ant-table-tbody > tr > td {
    color: ${$primary};
  }

  .ant-table-tbody > tr:nth-of-type(odd) {
    background: ${$background};
  }

  #components-table-demo-drag-sorting tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  #components-table-demo-drag-sorting tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  } */
`;
