import { Button, Spin } from 'antd';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import orderApi from '../../apis/order';
import { IOrder, IResendRequest, RequestStatus } from '../../interfaces/Order';
import { RootState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { Role } from '../../role/role';
import { DynamicContainer, FlexContainer } from '../../styles/Global';
import CustomInput from '../Form/FormItem/Input/Input';
import BaseModal from './BaseModal/BaseModal';

export interface IProps {
  visible: boolean;
  order?: IOrder;
  onConfirm?: (request: IResendRequest) => void;
  onCancel?: () => void;
  onReject?: (request: IResendRequest) => void;
  onApprove?: (request: IResendRequest) => void;
  onCreateNew?: (request: IResendRequest) => void;
}

const RequestResendModal = (props: IProps) => {
  const {
    visible,
    order,
    onConfirm,
    onCancel,
    onApprove,
    onReject,
    onCreateNew,
  } = props;
  const resendRequest = order?.resendRequest;
  const isNew = !resendRequest;
  const isPending = resendRequest?.status === RequestStatus.PENDING;
  const isRejected = resendRequest?.status === RequestStatus.REJECTED;
  const { currentUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );
  const isAdmin = currentUser?.role === Role.ADMIN;
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState<string>();
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    setReason(resendRequest?.reason);
  }, [order]);

  const handleConfirm = async () => {
    try {
      setLoading(true);

      let request;
      if (isNew) {
        request = await orderApi.createResendRequest(order?.id || '', {
          reason,
        });
      } else {
        request = await orderApi.updateResendRequest(
          order?.id || '',
          resendRequest?.id,
          { reason }
        );
      }
      if (!!request) {
        onConfirm?.(request);
        onCancel?.();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setReason(undefined);
  };

  const handleCancel = () => {
    setReason(resendRequest?.reason);
    onCancel?.();
  };

  const handleApprove = async () => {
    try {
      setLoadingAction(true);
      const request = await orderApi.updateResendRequestStatus(
        order?.id || '',
        resendRequest?.id || '',
        RequestStatus.APPROVED
      );
      if (request) {
        onApprove?.(request);
        onCancel?.();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAction(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoadingAction(true);
      const request = await orderApi.updateRefundRequestStatus(
        order?.id || '',
        resendRequest?.id || '',
        RequestStatus.REJECTED
      );
      if (request) {
        onReject?.(request);
        onCancel?.();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAction(false);
    }
  };

  const renderAdminFooter = () => {
    return isPending ? (
      <div>
        <Button
          style={{ backgroundColor: '#ff0000', color: '#FFF' }}
          onClick={handleReject}
          disabled={loadingAction}
        >
          {loadingAction ? <Spin /> : 'Reject'}
        </Button>
        <Button
          style={{ backgroundColor: '#5bb450', color: '#FFF' }}
          onClick={handleApprove}
          disabled={loadingAction}
        >
          {loadingAction ? <Spin /> : 'Approve'}
        </Button>
      </div>
    ) : null;
  };

  const renderSellerFooter = () => {
    return isPending || isNew ? (
      <FlexContainer>
        <Button onClick={handleClear}>Clear</Button>
        <div>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            style={{
              backgroundColor: '#4361EE',
              color: '#FFF',
            }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </FlexContainer>
    ) : isRejected ? (
      <Button
        onClick={() => {
          onCreateNew?.(resendRequest);
        }}
      >
        Create New
      </Button>
    ) : null;
  };

  return (
    <StyledModal
      visibleModal={visible}
      title={'Request Resend'}
      confirmLoading={loading}
      destroyOnClose={true}
      footer={isAdmin ? renderAdminFooter() : renderSellerFooter()}
      onSecondaryBtnClick={handleCancel}
    >
      {loading ? (
        <Spin />
      ) : (
        <DynamicContainer display="grid" alignitems="center">
          {!!resendRequest ? (
            <TextLabel>
              Requested On:{' '}
              {dayjs.default(resendRequest?.requestedAt).format('DD MMM YYYY')}
              {!!resendRequest?.approvedAt
                ? ` - (Approved on ${dayjs
                    .default(resendRequest?.approvedAt)
                    .format('DD MMM YYYY')})`
                : !!resendRequest?.rejectedAt
                ? ` - (Rejected on ${dayjs
                    .default(resendRequest?.rejectedAt)
                    .format('DD MMM YYYY')})`
                : !!resendRequest?.updatedAt
                ? ` - (Updated on ${dayjs
                    .default(resendRequest?.updatedAt)
                    .format('DD MMM YYYY')})`
                : null}
            </TextLabel>
          ) : null}
          <TextLabel>Reason</TextLabel>
          <CustomInput
            className="m__b--20"
            value={reason}
            placeholder="reason is optional"
            onChange={(e) => {
              setReason(e.target.value);
            }}
            disabled={(!isPending && !isNew) || isAdmin}
          />
        </DynamicContainer>
      )}
    </StyledModal>
  );
};

export default RequestResendModal;

const TextLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: left;
`;

const StyledModal = styled(BaseModal)`
  &.ant-modal {
    width: 640px !important;
  }

  .ant-modal-footer {
  }
`;
