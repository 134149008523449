export const Breakpoints: Record<string, number> = {
  xs: 375,
  sm: 576,
  md: 768, // tablet
  lg: 992,
  size1024: 1024, // big tablet
  xl: 1200, // desktop
  size1240: 1240,
  size1280: 1280,
  xxl: 1526,
  size1615: 1615,
  xxxl: 1680, // desktop 22"
  twok: 1920, // 2K screens
  fourk: 2560, // 4K screens
  eightk: 4096, // 8K screens
};
