import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";
import { $primary } from "../../styles/Variables";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: $primary }} spin />
);

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 100;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerWrapper>
      <Spin indicator={antIcon} />
    </LoadingSpinnerWrapper>
  );
};

export default LoadingSpinner;
