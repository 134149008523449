import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../../actions/auth';
import { RootState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { $primary } from '../../styles/Variables';

const { Header } = Layout;

const CustomHeader = styled(Header)`
  border-bottom: 1px solid ${$primary};
  z-index: 1000;
  background-color: #131921;

  &.ant-layout {
    min-height: 100vh;
  }

  &.ant-layout-header {
    background-color: #131921;
  }

  .logo {
    width: 120px;
    height: 31px;
  }
`;

// eslint-disable-next-line
enum MenuAdminActions {
  LOGOUT = 'LOGOUT',
}

const MainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );

  const logoutAction = async () => {
    dispatch(logout(() => navigate('/login')));
  };

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case MenuAdminActions.LOGOUT:
        logoutAction();
        return;
      default:
        return;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={MenuAdminActions.LOGOUT}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <CustomHeader>
      <div className="logo float--left">
        <span className="text-white font-weight--500 float--right font-size--medium">
          HD STICKER
        </span>
      </div>
      {currentUser && (
        <Dropdown
          className="text-white float--right"
          overlay={menu}
          trigger={['click']}
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {`${currentUser.role.toLocaleUpperCase()}: ${currentUser.username}`}
            <DownOutlined />
          </a>
        </Dropdown>
      )}
    </CustomHeader>
  );
};
export default MainHeader;
