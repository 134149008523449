export enum LocalStorageKeys {
  USER_LOGGED_IN = 'user_logged_in',
  USER_LOGGED_OUT = 'user_logged_out',
  CONNECT_WITH_GOOGLE = 'connect_with_google',
  CONNECT_WITH_DISCORD = 'connect_with_discord',
  CONNECT_WITH_YOUTUBE = 'connect_with_youtube',
  REDIRECT_URL = 'redirect_url',
  HIDDEN_ANNOUNCEMENTS = 'hidden_announcements',
  TEAM_EMPTY = 'team_empty',
  PROGRAM_HIDDEN_ANNOUNCEMENTS = 'program_hidden_announcements',
  HIDDEN_YOU_HAVE_BEEN_REMOVE_FROM_TEAM_MODAL = 'hidden_you_have_been_remove_from_team_modal',
  USER_ACCESS_PROGRAM = 'user_access_program',
  CATEGORY_RECIPE_SELECTED_FROM_HOMEPAGE = 'category_recipe_selected_from_homepage',
  RECIPE_RATINGS = 'recipe_ratings',
  NO_AD = 'no_ad',
  UPLOAD_FIRST_PHOTO = 'upload_first_photo',
  CLICKED_WORKOUTS = 'clicked_workouts',
  EXPAND_MY_SCHEDULE_INFO = 'expand_my_schedule_info',
  EXPAND_MY_SCHEDULE_WORKOUT = 'expand_my_schedule_workout',
  TOGGLE_OPTIONAL_VIDEO_SETTING_FIRST_TIME = 'toggle_optional_video_setting_first_time',
  FILTER_RECIPE = 'filter_recipe',
  SORT_BY_PROGRAM = 'sort_by_program',
  SCHEDULE_VIEW_MODE = 'schedule_view_mode',
}
