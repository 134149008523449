import { IUser } from './UserModel';

export enum ProductType {
  sticker = 'sticker',
  iphone = 'iphone',
}

export enum RequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export interface ISize {
  id?: string;
  description?: string;
  size: string;
  price: number;
  product: ProductType;
}

export interface IDesign {
  id?: string;
  sku?: string;
  design?: any;
  designUrl?: string;
  createdAt?: string;
  isSelected?: boolean;
}

export interface IOrderItem {
  id?: string;
  sizeId?: string;
  designId?: string;
  quantity: number;
  itemCost?: number;
  product?: ProductType;
}

export interface IOrderItemResponse {
  design?: IDesign;
  id?: string;
  quantity?: number;
  size: ISize;
  itemCost?: number;
}

export interface IRefundRequest {
  id: string;
  reason?: string;
  evidence: string;
  status: RequestStatus;
  requestedAt: string;
  updatedAt?: string;
  approvedAt?: string;
  rejectedAt?: string;
}

export interface IResendRequest {
  id: string;
  reason?: string;
  status: RequestStatus;
  requestedAt: string;
  updatedAt?: string;
  approvedAt?: string;
  rejectedAt?: string;
}

export interface IOrder {
  id?: string;
  createdAt?: string;
  orderId?: string;
  address?: string;
  country?: 'ca' | 'us';
  trackingReference?: string;
  designs?: IOrderItem[];
  orderDesigns?: IOrderItemResponse[];
  totalCost?: number;
  sellerId?: string;
  status?: string;
  seller?: IUser;
  refundRequest?: IRefundRequest;
  resendRequest?: IResendRequest;
}

export const enum SortOption {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const ResendRequestStatusLabelMap = {
  [RequestStatus.APPROVED]: 'Resend Approved',
  [RequestStatus.PENDING]: 'Pending Resend',
  [RequestStatus.REJECTED]: 'Resend Rejected',
};
