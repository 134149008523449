import querystring from 'querystring';

import {
  IDesign,
  IOrder,
  IRefundRequest,
  IResendRequest,
  ISize,
} from '../interfaces/Order';
import {
  PaginationParamsRequest,
  PaginationParamsResponse,
} from '../interfaces/PaginationParamsResponse';
import Client from './client';

class OrderApi {
  private static classInstance?: OrderApi;

  public static get instance() {
    if (!this.classInstance) {
      this.classInstance = new OrderApi();
    }
    return this.classInstance;
  }

  public getSizes(
    pagination: PaginationParamsRequest
  ): Promise<PaginationParamsResponse<ISize>> {
    const { page, perPage, all } = pagination;
    return Client.get(
      `/size?page=${page}&perPage=${perPage}&${all ? 'all' : ''}`
    );
  }

  public createSize(body: ISize): Promise<ISize> {
    return Client.post(`/size`, body);
  }

  public updateSize(id: string, body: ISize): Promise<ISize> {
    return Client.patch(`/size/${id}`, body);
  }

  public getSizeDetail(id: string): Promise<ISize> {
    return Client.get(`/size/${id}`);
  }

  public getOrders(
    queryParam: any,
    pagination: PaginationParamsRequest
  ): Promise<PaginationParamsResponse<IOrder>> {
    const { page, perPage, all } = pagination;
    return Client.get(
      `/order?${querystring.stringify(
        queryParam
      )}&page=${page}&perPage=${perPage}&${all ? 'all' : ''}`
    );
  }

  public searchOrders(
    queryParam: any,
    pagination: PaginationParamsRequest
  ): Promise<PaginationParamsResponse<IOrder>> {
    const { page, perPage, all } = pagination;
    return Client.get(
      `/order/search?${querystring.stringify(
        queryParam
      )}&page=${page}&perPage=${perPage}&${all ? 'all' : ''}`
    );
  }

  public exportOrdersCsv(
    queryParam: any,
    pagination: PaginationParamsRequest
  ): Promise<string> {
    const { all } = pagination;

    return Client.get(
      `/order/csv?${querystring.stringify(queryParam)}&${all ? 'all' : ''}`
    );
  }

  public importOrderCsv(body: FormData): Promise<Array<IOrder>> {
    return Client.post(`/order/upload/bulk`, body);
  }

  public createOrder(body: IOrder): Promise<IOrder> {
    return Client.post(`/order`, body);
  }

  public updateOrder(id: string, body: IOrder): Promise<IOrder> {
    return Client.patch(`/order/${id}`, body);
  }

  public updateOrderStatus(id: string, status: string): Promise<IOrder> {
    return Client.patch(`/order/${id}/status`, { status });
  }

  public deleteOrder(orderIds: string[]): Promise<IOrder> {
    return Client.post(`/order/delete`, { orderIds });
  }

  public bulkUpdateOrderStatus(
    orderIds: string[],
    status: string
  ): Promise<any> {
    return Client.post(`/order/status/bulk`, {
      orderIds,
      status,
    });
  }

  public bulkUpdateResendRequestStatus(
    requestIds: string[],
    status: string
  ): Promise<any> {
    return Client.post(`/order/resend-request/status/bulk`, {
      requestIds,
      status,
    });
  }

  public getOrderDetail(id: string): Promise<IOrder> {
    return Client.get(`/order/${id}`);
  }

  public createDesign(payload: FormData): Promise<IDesign> {
    return Client.post(`/design`, payload);
  }

  public uploadDesigns(payload: FormData): Promise<Array<IDesign>> {
    return Client.post(`/design/multiple`, payload);
  }

  public getDesigns(
    pagination: PaginationParamsRequest,
    queryParam: any
  ): Promise<PaginationParamsResponse<IDesign>> {
    const { page, perPage } = pagination;
    return Client.get(
      `/design?${querystring.stringify(
        queryParam
      )}&page=${page}&perPage=${perPage}`
    );
  }

  public updateSku(id: string, sku: string): Promise<any> {
    return Client.patch(`/design/${id}/sku`, { sku });
  }

  public updateMultipleDesignsSku(designs: Array<IDesign>): Promise<void> {
    return Client.patch(`/design/multiple/sku`, { designs: designs });
  }

  public deleteDesign(designs: Array<string>): Promise<any> {
    return Client.post(`/design/delete`, { designs: designs });
  }

  public bulkUpdateTracking(body: FormData): Promise<any> {
    return Client.post(`/order/update-tracking/bulk`, body);
  }

  public createRefundRequest(
    orderId: string,
    payload: FormData
  ): Promise<IRefundRequest> {
    return Client.post(`/order/${orderId}/refund-request`, payload);
  }

  public updateRefundRequest(
    orderId: string,
    id: string,
    payload: FormData
  ): Promise<IRefundRequest> {
    return Client.put(`/order/${orderId}/refund-request/${id}`, payload);
  }

  public updateRefundRequestStatus(
    orderId: string,
    id: string,
    status: string
  ): Promise<IRefundRequest> {
    return Client.patch(`/order/${orderId}/refund-request/${id}/status`, {
      status,
    });
  }

  public createResendRequest(
    orderId: string,
    body: {
      reason?: string;
    }
  ): Promise<IResendRequest> {
    return Client.post(`/order/${orderId}/resend-request`, body);
  }

  public updateResendRequest(
    orderId: string,
    id: string,
    body: {
      reason?: string;
    }
  ): Promise<IResendRequest> {
    return Client.put(`/order/${orderId}/resend-request/${id}`, body);
  }

  public updateResendRequestStatus(
    orderId: string,
    id: string,
    status: string
  ): Promise<IResendRequest> {
    return Client.patch(`/order/${orderId}/resend-request/${id}/status`, {
      status,
    });
  }

  public processImages(body: FormData) {
    return Client.post(`/image`, body, { responseType: 'arraybuffer' });
  }
}

export default OrderApi.instance;
