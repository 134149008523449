import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useState } from 'react';
import orderApi from '../../apis/order';
import { IDesign } from '../../interfaces/Order';
import { StyledModal } from '../Modal/SearchDesignModal';

export interface DesignsUploaderProps {
  visible: boolean;
  onUploaded?: (designs: Array<IDesign>) => void;
  onCancel?: () => void;
}

const DesignsUploader = (props: DesignsUploaderProps) => {
  const { visible, onUploaded, onCancel } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleSelect = async () => {
    if (fileList.length === 0) {
      return;
    }

    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.map((file) =>
        formData.append('designs', file.originFileObj || '')
      );
    }

    try {
      setLoading(true);
      const res = await orderApi.uploadDesigns(formData);
      setFileList([]);
      onUploaded?.(res);
    } catch (error) {
      // navigate(-1);
      console.error(error);
    } finally {
      setLoading(false);
      onCancel?.();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Click to select designs</div>
    </div>
  );

  return (
    <StyledModal
      visibleModal={visible}
      title={'Upload Designs'}
      // footer={null}
      onPrimaryBtnClick={handleSelect}
      onSecondaryBtnClick={() => {
        setFileList([]);
        onCancel?.();
      }}
      primaryBtn="Upload"
      secondaryBtn="Cancel"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        maxCount={10}
        multiple
        //@ts-ignore
        customRequest={dummyRequest}
      >
        {fileList.length >= 10 ? null : uploadButton}
      </Upload>
    </StyledModal>
  );
};

export default DesignsUploader;
