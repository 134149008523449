import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import styled from 'styled-components';
import { $background, $primary, $white } from '../../styles/Variables';

interface CustomButtonProps extends ButtonProps {
  ghost?: boolean;
  outline?: boolean;
  icon?: React.ReactNode;
}

const ButtonStyled = styled(Button)`
  width: 127px;
  height: 44px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
`;

const PrimaryButtonStyled = styled(ButtonStyled)<{ color?: string }>`
  background: ${$primary};
  border: none;
  color: white;

  &:hover,
  &:focus {
    background: ${$primary};
  }

  ${({ color }) =>
    color &&
    `
    background: ${color};

    &:hover,
    &:focus {
      background: ${color};
    }
  `}
`;

const PrimaryGhostButtonStyled = styled(ButtonStyled)<{ color?: string }>`
  background: transparent;
  border: none;
  color: ${$primary} !important;

  ${({ color }) =>
    color &&
    `
    color: ${color} !important;
  `}
`;

const PrimaryOutlineButtonStyled = styled(ButtonStyled)<{ color?: string }>`
  background: ${$white};
  border: 1px solid ${$primary};
  color: ${$primary} !important;
  padding: 4px 11px;

  &:hover,
  &:focus {
    background: ${$white};
    border: 1px solid ${$primary};
    color: ${$primary} !important;
  }

  ${({ color }) =>
    color &&
    `
    color: ${color} !important;

    &:hover,
    &:focus {
      background: ${$white};
      border: 1px solid ${$primary};
      color: ${color} !important;
    }
  `}
`;

const PrimaryIconButtonStyled = styled(Button)<{ color?: string }>`
  width: 24px;
  height: 24px;
  background: ${$background};
  border-radius: 4px;
  color: ${$primary};
  border: none;

  &:hover {
    background: ${$primary};
  }
`;

const PrimaryButton = (props: CustomButtonProps) => {
  const { ghost, outline, icon, ...attrs } = props;
  if (ghost) {
    return (
      <PrimaryGhostButtonStyled ghost {...attrs}></PrimaryGhostButtonStyled>
    );
  } else if (outline) {
    return (
      <PrimaryOutlineButtonStyled
        type="text"
        {...attrs}
      ></PrimaryOutlineButtonStyled>
    );
  } else if (icon) {
    return <PrimaryIconButtonStyled type="primary" {...attrs} icon={icon} />;
  } else {
    return (
      <PrimaryButtonStyled type="primary" {...attrs}></PrimaryButtonStyled>
    );
  }
};
export default PrimaryButton;
