import { Form, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import orderApi from '../../apis/order';
import { ISize, ProductType } from '../../interfaces/Order';
import { ButtonGroupContainer, PageContainer } from '../../styles/Global';
import { $background } from '../../styles/Variables';
import CustomForm from '../Form/Form';
import CustomInput from '../Form/FormItem/Input/Input';
import InputNumber from '../Form/FormItem/InputNumber/InputNumber';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const ActionsStyled = styled.div`
  border-radius: 8px;
  background-color: ${$background};
  padding: 20px;
  width: 164px;
  position: sticky;
  top: 90px;
`;

const ActionsSyncStyled = styled(ActionsStyled)`
  top: 200px;

  .ant-btn-loading {
    &::before {
      display: none;
    }
  }
`;

const SizeDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [sizeData, setSizeData] = useState<ISize>({
    size: '',
    description: '',
    price: 0,
    product: ProductType.sticker,
  });

  const fetchSize = async (id: string) => {
    try {
      const result = await orderApi.getSizeDetail(id);

      setSizeData(result);
      form.setFieldsValue({
        price: result.price,
        description: result.description,
        size: result.size,
        product: result.product,
      });
    } catch (error) {
      navigate(-1);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!!id) {
      fetchSize(id);
    }
  }, []);

  const updateSize = async () => {
    if (!id) {
      return;
    }

    try {
      setLoading(true);
      const { price, size, description, product } = form.getFieldsValue(true);

      const payload: ISize = {
        price,
        size,
        description,
        product,
      };

      await orderApi.updateSize(id, payload);
      message.success('Update size success!');
      navigate(-1);
    } catch (e) {
      console.error(e);
      message.error('Update size failed');
    } finally {
      setLoading(false);
    }
  };

  const createSize = async () => {
    try {
      setLoading(true);
      const payload: ISize = form.getFieldsValue(true);

      await orderApi.createSize(payload);
      message.success('Create size success!');
      navigate(-1);
    } catch (e) {
      console.error(e);
      message.error('Create size failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageContainer>
        <PageHeader title="Size Detail" showBackButton />
        <div className="w-70">
          {/* <CustomPageHeader
            className="site-page-header p--0"
            title={id ? programInfoData.name : "New program"}
            onBack={() => navigate(-1)}
          /> */}

          <CustomForm
            form={form}
            initialValues={sizeData}
            layout="vertical"
            scrollToFirstError
            requiredMark="optional"
            onFinish={() => {
              if (!id) {
                createSize();
                return;
              }

              updateSize();
            }}
          >
            <Form.Item
              name="size"
              label="Size Name"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input Size name',
                },
              ]}
            >
              <CustomInput placeholder="Size name" />
            </Form.Item>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: 'Please input price',
                },
              ]}
            >
              {/* <CustomInput placeholder="Size price" /> */}
              <InputNumber min={1} max={1000000} style={{ width: 120 }} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  type: 'string',
                },
                {
                  max: 255,
                  message: 'Description cannot be longer than 255 characters',
                },
              ]}
            >
              <CustomInput placeholder="Size description (Optional)" />
            </Form.Item>
            <Form.Item
              name="product"
              label="Product"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please select product',
                },
              ]}
            >
              <Select
                defaultValue={ProductType.sticker}
                style={{ width: 120 }}
                onChange={(value) => {
                  form.setFieldValue('product', value);
                }}
                options={[
                  { value: ProductType.sticker, label: 'Sticker' },
                  { value: ProductType.iphone, label: 'Iphone' },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <ButtonGroupContainer>
                <PrimaryButton htmlType="submit">Save</PrimaryButton>
              </ButtonGroupContainer>
            </Form.Item>
          </CustomForm>
        </div>

        {/* {!!id && (
          <div className="w-25 p__h--90">
            <ActionsStyled>
              <Button
                className="w-100 font-weight--300 bg--transparent border--none text-danger text-align--left p__v--4 p__h--11"
                icon={<DeleteOutlined className="font-size--16 p__r--8" />}
                type="text"
              >
                <span className="m__l--10 font-size--14">Remove</span>
              </Button>
            </ActionsStyled>
          </div>
        )} */}
      </PageContainer>
      {/* {showCancelConfirmationModal && (
        <BaseModal
          primaryBtn="Confirm"
          secondaryBtn="Cancel"
          // onPrimaryBtnClick={() => onCancel(true)}
          onSecondaryBtnClick={() => setShowCancelConfirmationModal(false)}
        >
          Quit editing? Changes you made so far will not be saved
        </BaseModal>
      )} */}
    </>
  );
};
export default SizeDetail;
