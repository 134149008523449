import { css } from 'styled-components';
import {
  $background,
  $lightwhite,
  $primary,
  $secondary,
  $white,
} from './Variables';

const DisplayStyle = css`
  // Display
  .d {
    &-none {
      display: none !important;
    }

    &-block {
      display: block;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-contents {
      display: contents;
    }

    &-relative {
      position: relative;
    }

    &-absolute {
      position: absolute;
    }

    &-grid {
      display: grid;
    }
  }

  // Justify Content
  .justify-content {
    &--center {
      justify-content: center;
    }

    &--between {
      justify-content: space-between;
    }

    &--around {
      justify-content: space-around;
    }

    &--end {
      justify-content: flex-end !important;
    }
    &--flex-end {
      justify-content: flex-end;
    }
  }

  // Justify Items
  .justify-items {
    &--center {
      justify-items: center;
    }
  }

  // Align Items
  .align-items {
    &--start {
      align-items: start !important;
    }

    &--center {
      align-items: center !important;
    }

    &--stretch {
      align-items: stretch;
    }

    &--baseline {
      align-items: baseline;
    }

    &--end {
      align-items: flex-end !important;
    }
  }

  .align-self {
    &--start {
      align-self: flex-start;
    }

    &--center {
      align-self: center;
    }

    &--end {
      align-self: flex-end;
    }
  }

  // Flex Wrap
  .flex-wrap {
    flex-wrap: wrap;

    &--noWrap {
      flex-wrap: nowrap;
    }
  }

  .flex {
    &--1 {
      flex: 1;
    }

    &-direction {
      &--column {
        flex-direction: column !important;
      }
      &--column-reverse {
        flex-direction: column-reverse !important;
      }
      &--row {
        flex-direction: row !important;
      }
      &--row-reverse {
        flex-direction: row-reverse !important;
      }
    }

    &-basis {
      &--auto {
        flex-basis: auto !important;
      }
    }
  }

  // fixes to combine with flex--1
  .w--0 {
    width: 0; // for Safari
    min-width: 0; // for Firefox
  }

  .w--fit {
    width: fit-content;
  }

  .h--0 {
    height: 0; // for Safari
    min-height: 0; // for Firefox
  }

  .h--fit {
    height: fit-content;
  }

  .white-space {
    &--normal {
      white-space: normal !important;
    }
    &--no-wrap {
      white-space: nowrap !important;
    }
  }

  .box-shadow {
    &--standard {
      @include box-shadow;
    }
    &--none {
      box-shadow: none !important;
    }
  }

  .round {
    &--small {
      border-radius: 8px;
    }
  }

  // Position
  .position--sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 9;
  }

  .position--fixed {
    position: fixed;
    z-index: 9;
  }

  .position--relative {
    position: relative;
  }

  // opacity
  .opacity {
    &--1 {
      opacity: 1 !important;
    }

    &--05 {
      opacity: 0.5 !important;
    }

    &--0 {
      opacity: 0 !important;
    }
  }

  .bg {
    background-color: ${$background};

    &--primary {
      background-color: ${$primary} !important;
    }

    &--secondary {
      background-color: ${$secondary} !important;
    }

    &--white {
      background-color: ${$white} !important;
    }

    &--lightwhite {
      background-color: ${$lightwhite} !important;
    }

    &--transparent {
      background-color: transparent !important;
    }
  }

  .text-align {
    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  .b--auto {
    bottom: auto !important;
  }

  .b--0 {
    bottom: 0;
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  .float-right {
    float: right;
  }

  .cursor {
    &--pointer {
      cursor: pointer !important;
    }
  }

  .float {
    &--left {
      float: left !important;
    }

    &--right {
      float: right !important;
    }
  }

  .overflow {
    &-scroll {
      overflow: scroll;
    }

    &-hidden {
      overflow: hidden;
    }

    &-auto {
      overflow: auto;
    }
  }
`;

export default DisplayStyle;
