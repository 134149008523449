import { Modal } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export const CustomModalStyle = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 5px 50px;

    .ant-modal-title {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0.01em;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
    padding-top: 55px;
    padding: 16px 50px;
    text-align: center;
  }

  .ant-modal-footer {
    text-align: right;
    border-top: none;
    padding: 10px 0 15px;
    margin-left: auto;

    button {
      border-radius: 5px;
      padding: 0 10px;
    }
  }
`;

export interface BaseModalProps {
  visibleModal: boolean;
  title?: string;
  isShowCloseBtn?: boolean;
  clickOutsideClose?: boolean;
  primaryBtn?: string;
  secondaryBtn?: string;
  footer?: ReactNode;
  children?: ReactNode;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  zIndex?: number;
}

const BaseModal: React.FC<BaseModalProps> = (props) => {
  const {
    visibleModal,
    title,
    isShowCloseBtn,
    clickOutsideClose,
    primaryBtn,
    secondaryBtn,
    confirmLoading,
    destroyOnClose = true,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
    children,
    zIndex,
  } = props;

  return (
    <CustomModalStyle
      open={visibleModal}
      title={title}
      mask={isShowCloseBtn}
      maskClosable={clickOutsideClose}
      okText={primaryBtn}
      cancelText={secondaryBtn}
      onOk={onPrimaryBtnClick}
      onCancel={onSecondaryBtnClick}
      confirmLoading={confirmLoading}
      destroyOnClose={destroyOnClose}
      centered
      zIndex={zIndex}
      {...props}
    >
      {children}
    </CustomModalStyle>
  );
};

export default BaseModal;
