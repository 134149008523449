import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Skeleton, Switch, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import Input from 'antd/lib/input/Input';
import range from 'lodash/range';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import orderApi from '../../apis/order';
import { useHasMounted } from '../../hooks';
import { useDebounceValue } from '../../hooks/useDebouncedValue';
import { IDesign, ISize } from '../../interfaces/Order';
import { PaginationParamsResponse } from '../../interfaces/PaginationParamsResponse';
import { DynamicContainer } from '../../styles/Global';
import CustomInput from '../Form/FormItem/Input/Input';
import BaseModal from '../Modal/BaseModal/BaseModal';

interface DesignValue {
  label: string | React.ReactNode;
  value: string;
  refValue: string;
}

const START_PAGE = 1;
const PER_PAGE = 20;

export interface SearchDesignProps {
  visible: boolean;
  previewImage?: string;
  previewTitle?: string;
  sizes: ISize[];
  onSelect?: (design: IDesign) => void;
  onCancel?: () => void;
}

const SearchDesignModal = (props: SearchDesignProps) => {
  const { visible, onSelect, onCancel } = props;
  const [sku, setSku] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [designValue, setDesignValue] = useState<DesignValue[]>([]);
  const [designItems, setDesignItems] = useState<IDesign[]>([]);
  const [searchTerms, setSearchTerms] = useState<string>('');
  const debounceSearchTerms = useDebounceValue(searchTerms, 500);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [designLink, setDesignLink] = useState<string>();
  const mounted = useHasMounted();
  const [itemsPagination, setItemsPagination] = useState<
    PaginationParamsResponse<IDesign>
  >({
    items: [],
    page: START_PAGE,
    perPage: PER_PAGE,
    total: 0,
    pageCount: 0,
  });
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    getDesign();
  }, [debounceSearchTerms]);

  useEffect(() => {
    if (currentPage > 1) {
      getDesign();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!mounted()) return;

    isSearch && getDesign();
  }, [isSearch, mounted]);

  const handleChange: UploadProps['onChange'] = (file: any) => {
    setFileList(
      file?.fileList.length
        ? [
            {
              ...file.fileList[0],
              status:
                file.fileList[0].status === 'error'
                  ? 'done'
                  : file.fileList[0].status,
            },
          ]
        : file?.fileList
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSelect = async () => {
    if (isSearch) {
      if (selectedIndex === undefined) return;

      onSelect?.(itemsPagination.items?.[selectedIndex]);
      onCancel?.();
      return;
    }

    if (
      !isSearch &&
      fileList.length === 0 &&
      (!designLink || designLink.length === 0)
    ) {
      return;
    }

    const formData = new FormData();
    if (fileList.length > 0) {
      formData.append('design', fileList[0]?.originFileObj || '');
    } else {
      formData.append('designUrl', designLink || '');
    }
    formData.append('sku', sku);

    try {
      setActionLoading(true);
      const res = await orderApi.createDesign(formData);
      setFileList([]);
      setSku('');
      setIsSearch(true);
      onSelect?.(res);
    } catch (error) {
      // navigate(-1);
      console.error(error);
    } finally {
      setActionLoading(false);
      onCancel?.();
    }
  };

  const getDesign = async () => {
    try {
      setLoading(true);
      const res = await orderApi.getDesigns(
        {
          page: currentPage,
          perPage: PER_PAGE,
        },
        { sku: debounceSearchTerms || '' }
      );

      setItemsPagination(res);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const fetchDesign = async (): Promise<DesignValue[]> => {
    return orderApi
      .getDesigns(
        {
          page: 1,
          perPage: PER_PAGE,
        },
        debounceSearchTerms
      )
      .then((body: PaginationParamsResponse<IDesign>) => {
        const { page, pageCount, items, total } = body;
        setDesignItems(
          page === currentPage ? items : [...designItems, ...items]
        );
        setHasMore(Number(page) < Number(pageCount));
        const tmp: Array<IDesign> = items;
        return tmp.map((design) => {
          return {
            label: (
              <ItemWrapper>
                <img src={design.designUrl} alt={design.sku} />
              </ItemWrapper>
            ),
            value: design.id,
            refValue: design.designUrl,
          } as DesignValue;
        });
      });
  };

  const fetchMoreData = () => {
    if (hasMore && designItems.length) {
      setCurrentPage((page) => page + 1);
    }
  };

  return (
    <StyledModal
      visibleModal={visible}
      title={isSearch ? 'Search Design' : 'Upload Design'}
      // footer={null}
      onPrimaryBtnClick={handleSelect}
      onSecondaryBtnClick={() => {
        onCancel?.();
      }}
      primaryBtn="Select"
      secondaryBtn="Cancel"
      confirmLoading={actionLoading}
      destroyOnClose={true}
    >
      <DynamicContainer display="flex" alignitems="center">
        <SwitchLabel>
          Toggle to {!isSearch ? 'Search' : 'Upload'} Design
        </SwitchLabel>
        <Switch checked={isSearch} onChange={(e) => setIsSearch(!isSearch)} />
      </DynamicContainer>

      <div id="infinite-scroll-container">
        {isSearch ? (
          <>
            <Label>Pick a Design</Label>
            <Input
              placeholder="Search by sku"
              value={searchTerms}
              onChange={(event) => setSearchTerms(event.target.value)}
            />
            <hr className="m__t--10 m__b--10" />

            {loading ? (
              <ListDesign>
                {range(20).map((n) => (
                  <DesignItemContainer $selected={false} key={n}>
                    <Skeleton.Image active />
                  </DesignItemContainer>
                ))}
              </ListDesign>
            ) : (
              <ListDesign>
                {itemsPagination.items?.map((d, idx) => (
                  <DesignItemContainer
                    key={idx}
                    $selected={idx === selectedIndex}
                    onClick={() => setSelectedIndex(idx)}
                  >
                    <DesignItem src={d.designUrl} />
                  </DesignItemContainer>
                ))}
              </ListDesign>
            )}

            <Pagination
              className="m__t--20"
              total={itemsPagination.total}
              showTotal={(total) => `Total ${total} items`}
              // defaultPageSize={PER_PAGE}
              showSizeChanger={false}
              defaultCurrent={1}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
            {/* <div style={{ height: 300 }}>
              <InfiniteScroll
                dataLength={designItems.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4 style={{ textAlign: 'center' }}>Loading.....</h4>}
                scrollableTarget="infinite-scroll-container"
                // scrollThreshold={0.9}
                initialScrollY={0}
              >
                <ListDesign>
                  {designItems?.map((d, idx) => (
                    <DesignItemContainer
                      key={idx}
                      $selected={idx === selectedIndex}
                      onClick={() => setSelectedIndex(idx)}
                    >
                      <DesignItem src={d.designUrl} />
                    </DesignItemContainer>
                  ))}
                </ListDesign>
              </InfiniteScroll>
            </div> */}
          </>
        ) : (
          <>
            <Label>SKU</Label>
            <CustomInput
              className="m__b--20"
              value={sku}
              onChange={(e) => {
                setSku(e.target.value);
                // setError(!Boolean(e.target.value));
              }}
            />
            {/* {error && <p>SKU is missing</p>} */}
            <Label>Design Link</Label>
            <CustomInput
              className="m__b--20"
              value={designLink}
              placeholder="Paste design link here..."
              onChange={(e) => {
                setDesignLink(e.target.value);
                // setError(!Boolean(e.target.value));
              }}
              disabled={fileList.length > 0}
            />
            <p className="m__b--20">Or upload a design file</p>
            <StyledUpload
              listType="picture-card"
              fileList={fileList}
              // onPreview={handlePreview}
              onChange={handleChange}
              multiple={false}
              disabled={!!designLink && designLink.length > 0}
              accept="image/*"
            >
              {fileList.length >= 1 ? null : uploadButton}
            </StyledUpload>
          </>
        )}
      </div>
    </StyledModal>
  );
};

export default SearchDesignModal;

export const StyledModal = styled(BaseModal)`
  &.ant-modal {
    width: 640px !important;
  }

  .ant-modal-footer {
  }
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list-item-container {
    width: 160px;
    height: 160px;
  }
`;

const Label = styled.p`
  text-align: left;
  margin-bottom: 5px;
`;

const SwitchLabel = styled.p`
  text-align: left;
  margin: 0 10px 0 0;
  font-weight: 600;
`;

const ItemWrapper = styled.div`
  height: 100px;
  margin: auto;

  img {
    max-height: 98px;
    max-width: 300px;
    /* width: auto; */
    object-fit: contain;
    margin: auto;
  }
`;

const ListDesign = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  min-height: 60px;
  max-height: 430px;
  overflow: auto;
`;

const DesignItemContainer = styled.div<{ $selected: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  border: 2px solid #484839;
  display: grid;
  align-items: center;
  justify-content: center;

  ${({ $selected }) =>
    $selected &&
    `
    border-color: #095cef;
  `}
`;

const DesignItem = styled.img`
  max-width: 92px;
  max-height: 92px;
  object-fit: contain;
`;
