import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../reducers';
import { IAuthState } from '../reducers/auth';
import { Role } from '../role/role';

const PrivateRoute = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: Array<Role>;
}) => {
  const location = useLocation();
  const { isAuthenticated, authUser, loading } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );

  // if (loading) {
  //   return <LoadingSpinner />;
  // }

  const userHasRequiredRole =
    authUser && roles.includes(authUser?.role!) ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // if (isAuthenticated && !userHasRequiredRole) {
  //   return <Navigate to="/403" />;
  // }

  return children;
};

export default PrivateRoute;
