import { css } from 'styled-components';
import { $border, $maxBorderRadius } from './Variables';

function createBorderRadiusCss() {
  let styles = '';

  for (let i = 1; i < $maxBorderRadius / 2; i++) {
    styles = styles.concat(`
      .border-radius--${2 * i} {
        border-radius: ${2 * i}px !important;
      }
    `);
  }

  return css`
    ${styles}
  `;
}

const BorderStyle = css`
  .border {
    border: 1px solid ${$border} !important;
  }

  .border--top {
    border-top: 1px solid ${$border} !important;
  }

  .border--right {
    border-right: 1px solid ${$border} !important;
  }

  .border--bottom {
    border-bottom: 1px solid ${$border} !important;
  }

  .border--left {
    border-left: 1px solid ${$border} !important;
  }

  .border--none {
    border: none !important;
  }

  ${createBorderRadiusCss()}
`;

export default BorderStyle;
