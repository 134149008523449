import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import config from '../config/config';
import { RESPONSE_ERROR } from '../constants/error';
import { UserLoginResponse } from '../interfaces/UserModel';
import { removeAuthTokens, setAuthTokens } from '../utils/users';
// import { removeAuthTokens, setAuthTokens } from '~utils/users';

declare module 'axios' {
  // interface AxiosResponse<T> extends Promise<T> {};
}

const MAX_REFRESH_TOKEN_RETRY = 3;
class Client {
  public static classInstance?: Client;
  protected axiosInstance: AxiosInstance;
  protected retryTimes = 0;
  private isFetchingAccessToken = false;

  public constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.API_HOST,
      /* For enabling sticky sessions */
      // withCredentials: true,
    });
    this.axiosInstance.defaults.headers.common['Content-Type'] =
      'application/json';

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  public static get instance() {
    if (!this.classInstance) {
      this.classInstance = new Client();
    }

    return this.classInstance;
  }

  private _initializeRequestInterceptor = () => {
    this.axiosInstance.interceptors.request.use((config) => {
      if (typeof window !== 'undefined') {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      return config;
    });
  };

  private _initializeResponseInterceptor = () => {
    this.axiosInstance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };
  private _handleResponse = ({ data }: any) => data;

  protected _handleError = async (error: AxiosError) => {
    if (!error.response && !error.request) {
      return Promise.reject(error);
    }
    if (!error.response && error.request) {
      return Promise.reject({ message: 'No response received' });
    }
    if (error.response?.status !== RESPONSE_ERROR.UNAUTHORIZED) {
      return Promise.reject(error.response?.data);
    }
    const canRefetch =
      !this.isFetchingAccessToken &&
      error.config &&
      error.response &&
      error.response.status === RESPONSE_ERROR.UNAUTHORIZED;
    if (!canRefetch) {
      return Promise.reject(error);
    }
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return Promise.reject(error.response?.data);
    }

    this.isFetchingAccessToken = true;
    try {
      const res = await this.getAccessToken(refreshToken);
      setAuthTokens(res.accessToken, res.refreshToken);
      if (error.config?.headers) {
        error.config.headers.Authorization = `Bearer ${res.accessToken}`;
      }

      // Re-try the failed request
      if (this.retryTimes > MAX_REFRESH_TOKEN_RETRY) {
        this.retryTimes = 0;
        return Promise.reject(new Error('Failed to retry api!'));
      }
      this.retryTimes++;
      this.isFetchingAccessToken = false;

      return this.axiosInstance(error.config as AxiosRequestConfig);
    } catch (e) {
      console.error(e);
      this.isFetchingAccessToken = false;
      removeAuthTokens();

      // store.dispatch(authActions.setCurrentUser(null));
      // Redirect to login page when:
      // - Either failed to retrieve new access_token
      // - Or refresh token expired
      window.location.href = '/login';
    }
  };

  public getAccessToken(refreshToken: string): Promise<UserLoginResponse> {
    return this.axiosInstance.get(
      `/auth/access_token?refresh_token=${refreshToken}`
    );
  }

  public get<T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.axiosInstance.get(url, config);
  }

  public delete<T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.axiosInstance.delete(url, config);
  }

  public post<T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.axiosInstance.post(url, data, config);
  }

  public put<T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.axiosInstance.put(url, data, config);
  }

  public patch<T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.axiosInstance.patch(url, data, config);
  }
}

export default Client.instance;
