import { Statistic } from 'antd';
import styled from 'styled-components';
import { FlexContainer } from '../../styles/Global';
import { $black, $primary } from '../../styles/Variables';
import CustomSelect from '../Form/FormItem/Select/Select';

export const StyledSelect = styled(CustomSelect)`
  .ant-select-selector {
    border-radius: 8px !important;
    padding: 1px 3px;
    min-height: 44px;

    .ant-select-selection-item {
      border: none;
      border-radius: 8px;
      padding: 14px 0;
      color: ${$black};
      background-color: transparent;
      font-size: 14px;
      line-height: 14px;
      height: 44px;

      .anticon {
        color: ${$primary};
        font-size: 9px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    margin: auto;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 42px !important;
  }

  .ant-select-selection-placeholder {
    margin: auto;
  }
`;

export const SearchFilterContainer = styled.div<{ $isAdmin?: boolean }>`
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
  grid-template-columns: 1fr auto;

  ${({ $isAdmin }) => $isAdmin && `grid-template-columns: 1fr 1fr auto;`}
`;

export const Row = styled(FlexContainer)`
  /* max-width: 1000px; */
`;

export const ChartWrapper = styled.div`
  text-align: center;
`;

export const ChartContainer = styled.div`
  width: 500px;
`;

export const CardStats = styled(Statistic)`
  border: 1px solid #3868d5;
  border-radius: 12px;
  text-align: center;
  padding: 24px;
`;

export const CardStatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;
