import type { TableColumnsType, UploadFile, UploadProps } from 'antd';
import {
  DatePicker,
  Dropdown,
  Menu,
  Spin,
  Tooltip,
  Upload,
  message,
} from 'antd';
import * as dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import orderApi from '../../apis/order';
import userApi from '../../apis/user';
import {
  Country,
  OrderStatus,
  ResendRequestFilter,
  TrackingFilter,
} from '../../constants/order';
import { useHasMounted } from '../../hooks';
import {
  IOrder,
  IOrderItemResponse,
  ProductType,
  RequestStatus,
  ResendRequestStatusLabelMap,
  SortOption,
} from '../../interfaces/Order';
import { PaginationParamsResponse } from '../../interfaces/PaginationParamsResponse';
import { IUser } from '../../interfaces/UserModel';
import { RootState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { Role } from '../../role/role';
import { CustomTable, FlexContainer, PageContainer } from '../../styles/Global';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {
  BulkActionButton,
  CustomSize,
  DesignWrapper,
  NestedTable,
  NestedTableImage,
  SearchFilterContainer,
  SellerDateWrapper,
  SpinContainer,
  StatusTag,
  StyledCountry,
  StyledQuantity,
  StyledSearch,
  StyledSelect,
  ThreeDot,
} from './Order.styled';

const OrderList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [itemsPagination, setItemsPagintion] = useState<
    PaginationParamsResponse<IOrder>
  >({
    items: [],
    page: 1,
    perPage: 10,
    total: 0,
    pageCount: 0,
  });
  const { currentUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const isAdmin = currentUser?.role === Role.ADMIN;
  const [spinLoading, setSpinLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [country, setCountry] = useState('');
  const [product, setProduct] = useState('');
  const [sortFieldValue, setSortFieldValue] = useState('');
  const [sortOrderValue, setSortOrderValue] = useState('');
  const [trackingFilter, setTrackingFilter] = useState<TrackingFilter>();
  const [filterDate, setFilterDate] = useState<{
    startDate: any;
    endDate: any;
  }>();
  const [search, setSearch] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [sellers, setSellers] = useState<Array<IUser>>();
  const [sellerUsername, setSellerUsername] = useState<Array<string>>();
  const mounted = useHasMounted();
  const downloadLinkRef = createRef<HTMLAnchorElement>();
  const resendRequestOrdersSelected = useMemo(() => {
    const orders = itemsPagination.items.filter((order) =>
      (selectedRowKeys as Array<string>).includes(order?.id || '')
    );

    return orders.filter((order) => !!order.resendRequest);
  }, [selectedRowKeys, itemsPagination]);

  useEffect(() => {
    if (!mounted()) return;

    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }

    searchOrder();
  }, [
    mounted,
    status,
    country,
    search,
    filterDate,
    sortFieldValue,
    sortOrderValue,
    trackingFilter,
    product,
    sellerUsername,
  ]);

  useEffect(() => {
    if (!mounted()) return;

    searchOrder();
  }, [mounted, currentPage, perPage]);

  useEffect(() => {
    if (!mounted()) return;

    if (isAdmin) {
      getSellers();
    }
  }, [mounted, isAdmin]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteOrder = async (id: string) => {
    if (!id) {
      return;
    }

    try {
      setActionLoading(true);
      await orderApi.deleteOrder([id]);
      message.success('Delete order success!');
      // update list
      const tmp = cloneDeep(itemsPagination.items);
      const idx = tmp.findIndex((t) => t.id === id);
      if (idx > -1) {
        tmp.splice(idx, 1);
        setItemsPagintion({
          ...itemsPagination,
          items: tmp,
        });
      }
    } catch (e) {
      message.error('Delete order fail!');
      console.error(e);
    } finally {
      setActionLoading(false);
    }
  };

  const quickActionHandle = async (id: string, status: string) => {
    try {
      setActionLoading(true);
      await orderApi.updateOrderStatus(id, status);
      message.success('Update order status success!');

      // Update list order
      const tmp = cloneDeep(itemsPagination.items);
      const idx = tmp.findIndex((t) => t.id === id);
      if (idx > -1) {
        tmp[idx].status = status;
        setItemsPagintion({
          ...itemsPagination,
          items: tmp,
        });
      }
    } catch (e) {
      message.error('Update order status fail!');
      console.error(e);
    } finally {
      setActionLoading(false);
    }
  };

  const getSellers = async () => {
    try {
      const sellers = await userApi.listUsers({ all: true });
      setSellers(sellers.items);
    } catch (e) {
      message.error('Get sellers fail!');
      console.error(e);
    }
  };

  const expandedRowRender = (row: IOrder) => {
    const columns: TableColumnsType<IOrderItemResponse> = [
      {
        title: 'Image',
        dataIndex: 'design',
        key: 'designUrl',
        render: (_: any, order: IOrderItemResponse) => (
          <NestedTableImage src={order?.design?.designUrl} />
        ),
        width: '15%',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (_: any, order: IOrderItemResponse) => (
          <StyledQuantity $moreThan1={order?.quantity! > 1}>
            {order?.quantity}
          </StyledQuantity>
        ),
        width: '10%',
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: (_: any, order: IOrderItemResponse) => (
          <CustomSize
            $2in={order?.size?.size === '2in'}
            $3in={order?.size?.size === '3in'}
            $4in={order?.size?.size === '4in'}
            $5in={order?.size?.size === '5in'}
            $6in={order?.size?.size === '6in'}
            $card={order?.size?.size === 'Card'}
          >
            {order?.size?.size}
          </CustomSize>
        ),
        width: '10%',
      },
      {
        title: 'Sku',
        dataIndex: 'design',
        key: 'sku',
        render: (_: any, order: IOrderItemResponse) => (
          <p>{order?.design?.sku}</p>
        ),
        width: '10%',
      },
      {
        title: 'Price',
        dataIndex: 'size',
        key: 'price',
        render: (_: any, order: IOrderItemResponse) => (
          <p>${order?.itemCost?.toLocaleString('en-US')}</p>
        ),
        width: '60%',
      },
    ];

    const data = row.orderDesigns;

    return (
      <NestedTable
        className="nested-table"
        columns={columns as any}
        dataSource={data}
        pagination={false}
        showHeader={false}
        bordered
      />
    );
  };

  const columns = [
    {
      title: isAdmin ? 'Seller/Date' : 'Date',
      key: 'createdAt',
      editable: false,
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      fixed: 'left',
      render: (_: any, order: IOrder) => (
        <Link to={`/order/${order?.id}/edit`}>
          <SellerDateWrapper>
            {isAdmin && <p>{order?.seller?.username}</p>}
            <p>{dayjs.default(order?.createdAt).format('DD MMM YYYY')}</p>
          </SellerDateWrapper>
        </Link>
      ),
      width: '6%',
    },
    {
      title: 'Order Id',
      key: 'orderId',
      editable: false,
      dataIndex: 'orderId',
      fixed: 'left',
      render: (_: any, order: IOrder) => (
        <Tooltip title={order?.orderId}>
          <div className="ellipsis" style={{ maxWidth: 160 }}>
            <Link to={`/order/${order?.id}/edit`}>
              <p>{order?.orderId}</p>
            </Link>
          </div>
        </Tooltip>
      ),
      width: '8%',
    },
    {
      title: 'Address',
      key: 'address',
      editable: false,
      dataIndex: 'address',
      width: '15%',
      fixed: 'left',
      render: (_: any, order: IOrder) => (
        <div style={{ whiteSpace: 'pre-wrap' }}>{order?.address}</div>
      ),
    },
    {
      title: 'Designs',
      key: 'designs',
      editable: false,
      dataIndex: 'designs',
      width: '10%',
      render: (_: any, order: IOrder) => (
        <DesignWrapper $totalItems={order.orderDesigns?.length || 0}>
          {order.orderDesigns?.slice(0, 4)?.map((od, idx) => (
            <img key={idx} src={od.design?.designUrl} alt={od.design?.sku} />
          ))}
          {!!order.orderDesigns && order.orderDesigns?.length >= 5 && (
            <ThreeDot>...</ThreeDot>
          )}
        </DesignWrapper>
      ),
    },
    {
      title: 'Tracking',
      key: 'trackingReference',
      editable: false,
      dataIndex: 'trackingReference',
      width: '12%',
      filters: [
        {
          text: 'No Tracking',
          value: TrackingFilter.NoTracking,
        },
        {
          text: 'Tracking only',
          value: TrackingFilter.TrackingOnly,
        },
      ],
      filterMultiple: false,
      onFilter: () => {
        return true;
      },
      render: (_: any, order: IOrder) => (
        <Tooltip trigger={['hover']} title={order?.trackingReference}>
          <div className="ellipsis" style={{ maxWidth: 180 }}>
            {order?.trackingReference}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Country',
      key: 'country',
      editable: false,
      dataIndex: 'country',
      width: '5%',
      render: (_: any, order: any) => (
        <StyledCountry $cad={order?.country === Country.CANADA}>
          {order?.country === Country.CANADA ? 'CANADA' : 'US'}
        </StyledCountry>
      ),
    },
    {
      title: 'Cost',
      key: 'totalCost',
      editable: false,
      dataIndex: 'totalCost',
      sorter: true,
      showSorterTooltip: false,
      width: '6%',
      render: (_: any, order: IOrder) => (
        <p>
          $
          {order.status === OrderStatus.CANCELLED
            ? 0
            : order?.totalCost?.toLocaleString('en-US')}
        </p>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      editable: false,
      dataIndex: 'status',
      width: '10%',
      render: (_: any, order: IOrder) => {
        return !!order?.resendRequest ? (
          <FlexContainer $direction="column">
            <StatusTag
              $process={order?.status === OrderStatus.PROCESSING}
              $shipped={order?.status === OrderStatus.SHIPPED}
              $reshipped={order?.status === OrderStatus.RESHIPPED}
              $cancel={order?.status === OrderStatus.CANCELLED}
            >
              {order?.status}
            </StatusTag>
            <StatusTag
              $process={order?.resendRequest.status === RequestStatus.PENDING}
              $pending={order?.resendRequest.status === RequestStatus.APPROVED}
              $rejected={order?.resendRequest.status === RequestStatus.REJECTED}
              $fontSize={10}
              className="m__t--4"
            >
              {ResendRequestStatusLabelMap[order?.resendRequest?.status]}
            </StatusTag>
          </FlexContainer>
        ) : (
          <StatusTag
            $process={order?.status === OrderStatus.PROCESSING}
            $shipped={order?.status === OrderStatus.SHIPPED}
            $reshipped={order?.status === OrderStatus.RESHIPPED}
            $cancel={order?.status === OrderStatus.CANCELLED}
          >
            {order?.status}
          </StatusTag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      editable: false,
      dataIndex: 'id',
      render: (_: any, order: any) => (
        <Dropdown
          disabled={actionLoading}
          trigger={['hover']}
          overlay={menuQuickAction(order)}
        >
          <a>Quick Action</a>
        </Dropdown>
      ),

      // hideForUser: true,
    },
  ];

  const menuQuickAction = (order: IOrder) => (
    <Menu style={{ marginBottom: '12px' }}>
      {isAdmin && order.status === OrderStatus.PENDING && (
        <Menu.Item
          key="process"
          onClick={() =>
            order.id && quickActionHandle(order.id, OrderStatus.PROCESSING)
          }
        >
          Process
        </Menu.Item>
      )}
      {isAdmin &&
        !!order.status &&
        [OrderStatus.PROCESSING, OrderStatus.RESHIPPED].includes(
          order.status as OrderStatus
        ) && (
          <Menu.Item
            key="finish"
            onClick={() =>
              order.id && quickActionHandle(order.id, OrderStatus.SHIPPED)
            }
          >
            Finish
          </Menu.Item>
        )}
      {isAdmin && order.status !== OrderStatus.CANCELLED && (
        <Menu.Item
          key="cancel"
          onClick={() =>
            order.id && quickActionHandle(order.id, OrderStatus.CANCELLED)
          }
        >
          Cancel
        </Menu.Item>
      )}
      {isAdmin && order.status === OrderStatus.SHIPPED && (
        <Menu.Item
          key="reship"
          onClick={() =>
            order.id && quickActionHandle(order.id, OrderStatus.RESHIPPED)
          }
        >
          Reship
        </Menu.Item>
      )}
      <Menu.Item key="edit">
        <Link to={`/order/${order?.id}/edit`}>Edit</Link>
      </Menu.Item>
      {!!order.status &&
        [OrderStatus.PENDING, OrderStatus.CANCELLED].includes(
          order.status as OrderStatus
        ) && (
          <Menu.Item
            key="delete"
            onClick={() => order.id && deleteOrder(order.id)}
          >
            Delete
          </Menu.Item>
        )}
    </Menu>
  );

  const menuBulkAction = () => (
    <Menu style={{ marginBottom: '12px' }}>
      {resendRequestOrdersSelected.length > 0 && (
        <Menu.Item
          key="approve-request"
          onClick={() => bulkActionResendRequestHandle(RequestStatus.APPROVED)}
        >
          Approve Resend Requests
        </Menu.Item>
      )}
      {resendRequestOrdersSelected.length > 0 && (
        <Menu.Item
          key="reject-request"
          onClick={() => bulkActionResendRequestHandle(RequestStatus.REJECTED)}
        >
          Reject Resend Requests
        </Menu.Item>
      )}
      <Menu.Item
        key="pending"
        onClick={() => bulkActionHandle(OrderStatus.PENDING)}
      >
        Change to Pending
      </Menu.Item>
      <Menu.Item
        key="process"
        onClick={() => bulkActionHandle(OrderStatus.PROCESSING)}
      >
        Change to Process
      </Menu.Item>
      <Menu.Item
        key="reshipped"
        onClick={() => bulkActionHandle(OrderStatus.RESHIPPED)}
      >
        Change to Reshipped
      </Menu.Item>
      <Menu.Item
        key="shipped"
        onClick={() => bulkActionHandle(OrderStatus.SHIPPED)}
      >
        Change to Shipped
      </Menu.Item>
      <Menu.Item
        key="cancel"
        onClick={() => bulkActionHandle(OrderStatus.CANCELLED)}
      >
        Change to Cancel
      </Menu.Item>
      <Menu.Item disabled key="download">
        Download
      </Menu.Item>
    </Menu>
  );

  const bulkActionHandle = async (status = OrderStatus.PENDING) => {
    if (!status || selectedRowKeys.length === 0) {
      return;
    }

    try {
      setBulkActionLoading(true);
      await orderApi.bulkUpdateOrderStatus(selectedRowKeys as string[], status);

      // Update list order
      const tmp = cloneDeep(itemsPagination.items);
      selectedRowKeys.forEach((id) => {
        const idx = tmp.findIndex((t) => t.id === id);
        if (idx > -1) {
          tmp[idx].status = status;
        }
      });
      setItemsPagintion({
        ...itemsPagination,
        items: tmp,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setBulkActionLoading(false);
    }
  };

  const bulkActionResendRequestHandle = async (status: RequestStatus) => {
    if (!status || selectedRowKeys.length === 0) {
      return;
    }

    try {
      setBulkActionLoading(true);
      await orderApi.bulkUpdateResendRequestStatus(
        resendRequestOrdersSelected.map(
          (order) => order.resendRequest?.id || ''
        ),
        status
      );

      // Update list order
      const tmp = cloneDeep(itemsPagination.items);
      resendRequestOrdersSelected.forEach((order) => {
        const idx = tmp.findIndex((t) => t.id === order.id);
        if (idx > -1) {
          if (!!tmp[idx].resendRequest) {
            tmp[idx].resendRequest!.status = status;
          }
        }
      });
      setItemsPagintion({
        ...itemsPagination,
        items: tmp,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setBulkActionLoading(false);
    }
  };

  const searchOrder = async () => {
    try {
      let query = {
        country,
        status,
        fromDate: filterDate?.startDate,
        toDate: filterDate?.endDate,
        tracking: trackingFilter,
        product,
        seller: sellerUsername,
      };
      if (sortFieldValue) {
        query[sortFieldValue] = sortOrderValue;
      }
      if (search) {
        (query as any).search = search;
      }

      const res = await orderApi.getOrders(query, {
        page: currentPage,
        perPage,
      });
      setItemsPagintion(res);
    } catch (error) {
      setItemsPagintion({
        items: [],
        page: 1,
        perPage,
        total: 0,
        pageCount: 0,
      });
      console.error(error);
    } finally {
    }
  };

  const loadData = async (
    page = itemsPagination?.page,
    perPage = itemsPagination?.perPage
  ) => {
    try {
      let query = {};
      if (sortFieldValue && sortOrderValue) {
        query[sortFieldValue] = sortOrderValue;
      }
      const res = await orderApi.getOrders(query, { page, perPage });

      setItemsPagintion(res);
    } catch (error) {
      setItemsPagintion({
        items: [],
        page,
        perPage,
        total: 0,
        pageCount: 0,
      });
      console.error(error);
    } finally {
    }
  };

  const onStateChanges = async (page: number, perPage: number) => {
    navigate(`${location.pathname}?page=${page}&perPage=${perPage}`, {
      replace: true,
    });
  };

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPerPage(pageSize);
    onStateChanges(page, pageSize);
  };

  const handleEventRow = (record: any) => {
    const order = record as IOrder;
    return {
      onClick: (e: any) => {
        e.stopPropagation();

        navigate(`/order/${order.id}/edit`);
      },
    };
  };

  const handleChange = (_pagination: any, _filters: any, sorter: any) => {
    // handle sort
    if (sorter) {
      if (sorter.column) {
        let sorterField = sorter.field;
        switch (sorter.field) {
          case 'createdAt':
            sorterField = 'byDate';
            break;
          case 'totalCost':
            sorterField = 'byTotalCost';
            break;
          case 'trackingReference':
            sorterField = 'byTracking';
            break;
        }
        const sorterOrder =
          sorter.order &&
          (sorter.order === 'ascend' ? SortOption.ASC : SortOption.DESC);
        setSortFieldValue(sorterField);
        setSortOrderValue(sorterOrder);
      } else {
        setSortFieldValue('');
        setSortOrderValue('');
      }
    }

    if (_filters) {
      const trackingValue = _filters['trackingReference']?.[0];
      setTrackingFilter(trackingValue ? trackingValue : undefined);
    }
  };

  const handleExportCsv = async () => {
    try {
      setSpinLoading(true);
      let query = {
        country,
        status,
        fromDate: filterDate?.startDate,
        toDate: filterDate?.endDate,
        tracking: trackingFilter,
        product,
        seller: sellerUsername,
      };

      if (sortFieldValue) {
        query[sortFieldValue] = sortOrderValue;
      }

      if (search) {
        (query as any).search = search;
      }

      const data = await orderApi.exportOrdersCsv(query, {
        page: currentPage,
        perPage: perPage,
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const tmpLink = document.createElement('a');
      tmpLink.href = url;
      const fileName = `HD Sticker ${new Date().toDateString()}-data.csv`;
      tmpLink.setAttribute('download', fileName);
      document.body.appendChild(tmpLink);
      tmpLink.click();
      tmpLink.remove();
    } catch (error) {
      console.error(error);
      message.error('Sorry an error occurred while processing your request!');
    } finally {
      setSpinLoading(false);
    }
  };

  const handleOpenCsvFile: UploadProps['onChange'] = (file: any) => {
    setFileList(
      file?.fileList.length
        ? [
            {
              ...file.fileList[0],
              status:
                file.fileList[0].status === 'error'
                  ? 'done'
                  : file.fileList[0].status,
            },
          ]
        : file?.fileList
    );
  };

  const hanldeUploadOrders = async () => {
    try {
      setSpinLoading(true);

      const formData = new FormData();
      formData.append('dataFile', fileList[0]?.originFileObj || '');

      await orderApi.importOrderCsv(formData);
    } catch (error) {
      console.error(error);
      message.error('Sorry an error occurred while processing your file!');
    } finally {
      await searchOrder();
      setSpinLoading(false);
      setFileList([]);
    }
  };

  const handleBulkUpdateTracking = async () => {
    try {
      setSpinLoading(true);

      const formData = new FormData();
      formData.append('dataFile', fileList[0]?.originFileObj || '');

      await orderApi.bulkUpdateTracking(formData);
    } catch (error) {
      console.error(error);
      message.error('Sorry an error occurred while processing your file!');
    } finally {
      await searchOrder();
      setSpinLoading(false);
      setFileList([]);
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title="Manage Order"
        customBlock={
          <FlexContainer>
            {isAdmin && (
              <BulkActionButton
                className="m__r--10"
                $disabled={bulkActionLoading || selectedRowKeys.length === 0}
                disabled={bulkActionLoading || selectedRowKeys.length === 0}
                trigger={['hover']}
                overlay={menuBulkAction()}
              >
                <span>Bulk Action</span>
              </BulkActionButton>
            )}
            {fileList.length > 0 && (
              <>
                <PrimaryButton
                  className="font-weight--500 m__r--10"
                  key="4"
                  onClick={handleBulkUpdateTracking}
                >
                  Update Tracking
                </PrimaryButton>
                <PrimaryButton
                  className="font-weight--500 m__r--10"
                  key="4"
                  onClick={hanldeUploadOrders}
                >
                  Upload
                </PrimaryButton>
              </>
            )}
            <Upload
              name="file"
              onChange={handleOpenCsvFile}
              multiple={false}
              fileList={fileList}
              accept=".csv"
            >
              {fileList.length < 1 && (
                <PrimaryButton className="font-weight--500 m__r--10" key="3">
                  Open CSV File
                </PrimaryButton>
              )}
            </Upload>
            <a ref={downloadLinkRef}>
              <PrimaryButton
                className="font-weight--500 m__r--10"
                key="2"
                onClick={handleExportCsv}
              >
                Export CSV
              </PrimaryButton>
            </a>
            <Link to="/order/create">
              <PrimaryButton className="font-weight--500" key="2">
                + Create Order
              </PrimaryButton>
            </Link>
          </FlexContainer>
        }
      />
      <SearchFilterContainer $isAdmin={isAdmin}>
        <StyledSearch
          placeholder="Type to search..."
          onSearch={(val) => setSearch(val)}
        />
        <DatePicker.RangePicker
          onChange={(val) => {
            if (Array.isArray(val) && val.length > 1) {
              setFilterDate({
                startDate: dayjs.default(val[0]).toISOString(),
                endDate: dayjs.default(val[1]).toISOString(),
              });
            } else {
              setFilterDate({
                startDate: null,
                endDate: null,
              });
            }
          }}
          allowClear
        />
        {isAdmin && (
          <StyledSelect
            className="w-100"
            placeholder="Choose Seller"
            // value={types}
            onChange={(val: any) => {
              setSellerUsername(val);
            }}
            options={sellers?.map((seller) => ({
              label: seller.username,
              value: seller.username,
            }))}
            mode="multiple"
          />
        )}
        <StyledSelect
          className="w-100"
          placeholder="Choose Product"
          // value={types}
          onChange={(val: any) => {
            setProduct(val);
          }}
          options={[
            { label: 'View All', value: '' },
            { label: 'Sticker', value: ProductType.sticker },
            { label: 'Iphone', value: ProductType.iphone },
          ]}
        />
        <StyledSelect
          className="w-100"
          placeholder="Choose country"
          // value={types}
          onChange={(val: any) => {
            setCountry(val);
          }}
          options={[
            { label: 'View All', value: '' },
            { label: 'US', value: Country.US },
            { label: 'CANADA', value: Country.CANADA },
          ]}
        />
        <StyledSelect
          className="w-100"
          placeholder="Choose status"
          // value={types}
          onChange={(val: any) => {
            setStatus(val);
          }}
          options={[
            { label: 'View All', value: '' },
            { label: 'Pending', value: OrderStatus.PENDING },
            { label: 'Processing', value: OrderStatus.PROCESSING },
            { label: 'Shipped', value: OrderStatus.SHIPPED },
            { label: 'Reshipped', value: OrderStatus.RESHIPPED },
            { label: 'Cancelled', value: OrderStatus.CANCELLED },
            {
              label: 'Pending Resend',
              value: ResendRequestFilter.PENDING_RESEND,
            },
            {
              label: 'Resend Approved',
              value: ResendRequestFilter.RESEND_APPROVED,
            },
            {
              label: 'Resend Rejected',
              value: ResendRequestFilter.RESEND_REJECTED,
            },
          ]}
        />
      </SearchFilterContainer>

      <CustomTable
        className="m__t--20"
        columns={columns as any}
        dataSource={itemsPagination.items}
        onChange={handleChange}
        pagination={{
          onChange: onPageChange,
          current: currentPage,
          total: itemsPagination.total,
          pageSize: itemsPagination.perPage,
          showSizeChanger: true,
          pageSizeOptions: [10, 50, 100],
        }}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
          defaultExpandedRowKeys: ['0'],
        }}
        rowSelection={isAdmin ? rowSelection : undefined}
        rowKey={(record) => record?.id}
        bordered
      />
      {spinLoading && (
        <SpinContainer>
          <Spin size="large" />
        </SpinContainer>
      )}
    </PageContainer>
  );
};

export default OrderList;
