import { Admin } from '../interfaces/Auth';
import { AuthActionTypeEnum, IAction } from '../interfaces/Reducer.interface';
import { Role } from '../role/role';

interface IUser {
  id?: string;
  role?: Role;
}

export interface IAuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  authUser: IUser | null;
  currentUser: Admin | null;
  loading: boolean;
  error: string;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isAuthenticating: true,
  authUser: null,
  currentUser: null,
  loading: false,
  error: '',
};

const auth = (state: IAuthState = initialState, { type, payload }: IAction) => {
  switch (type) {
    // Login
    case AuthActionTypeEnum.LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypeEnum.LOGIN_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    }
    case AuthActionTypeEnum.LOGIN_FAILURE: {
      return {
        ...state,
        refreshToken: '',
        error: payload?.error,
        isAuthenticated: false,
        loading: false,
      };
    }

    // Logout
    case AuthActionTypeEnum.LOGOUT_REQUEST: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        loading: true,
      };
    }
    case AuthActionTypeEnum.LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypeEnum.LOGOUT_FAILURE: {
      return {
        ...state,
        error: payload?.error,
        loading: false,
      };
    }

    case AuthActionTypeEnum.SET_AUTHENTICATING: {
      return {
        ...state,
        isAuthenticating: payload.isAuthenticating,
      };
    }

    // Get me
    case AuthActionTypeEnum.GET_ME_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypeEnum.GET_ME_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }
    case AuthActionTypeEnum.GET_ME_FAILURE: {
      return {
        ...state,
        error: payload?.error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
