import querystring from 'querystring';

import { StatsResponse } from '../interfaces/Stats';
import Client from './client';

class StatsApi {
  private static classInstance?: StatsApi;

  public static get instance() {
    if (!this.classInstance) {
      this.classInstance = new StatsApi();
    }
    return this.classInstance;
  }

  public stats(queryParam: any): Promise<StatsResponse> {
    return Client.get(`/stats?${querystring.stringify(queryParam)}`);
  }

  public userStats(username: string, queryParam: any): Promise<StatsResponse> {
    return Client.get(
      `/stats/${username}?${querystring.stringify(queryParam)}`
    );
  }
}

export default StatsApi.instance;
