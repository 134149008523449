export const UPDATE_VALIDATE_EMAIL_COUNTER = 'UPDATE_VALIDATE_EMAIL_COUNTER';
export const TOGGLE_WAITING_TO_VALIDATE_EMAIL =
  'TOGGLE_WAITING_TO_VALIDATE_EMAIL';
export const STORE_VALIDATE_EMAIL_INTERVAL = 'STORE_VALIDATE_EMAIL_INTERVAL';

// social
export const CONNECT_SOCIAL_SUCCESS = 'connect_social_success';
export const CONNECT_SOCIAL_FAILURE = 'connect_social_failure';

export const updateValidateEmailCounter = () => ({
  type: UPDATE_VALIDATE_EMAIL_COUNTER,
});

export const toggleWaitingToValidateEmail = () => ({
  type: TOGGLE_WAITING_TO_VALIDATE_EMAIL,
});

export const storeValidateEmailInterval = (
  interval: NodeJS.Timeout | null
) => ({
  type: STORE_VALIDATE_EMAIL_INTERVAL,
  interval,
});

export const connectSocialSuccess = (message: string) => ({
  type: CONNECT_SOCIAL_SUCCESS,
  message,
});

export const connectSocialFailure = (message: string) => ({
  type: CONNECT_SOCIAL_FAILURE,
  message,
});
