import { css } from 'styled-components';
import { $maxMargin, $maxPadding } from './Variables';

function createMarginCss() {
  let styles = '';

  for (let i = 0; i < $maxMargin / 2; i++) {
    styles = styles.concat(`
      .m--${2 * i} {
        margin: ${2 * i}px !important;
      }

      .m__h--${2 * i} {
        margin-left: ${2 * i}px !important;
        margin-right: ${2 * i}px !important;
      }

      .m__v--${2 * i} {
        margin-top: ${2 * i}px !important;
        margin-bottom: ${2 * i}px !important;
      }

      .m__t--${2 * i} {
        margin-top: ${2 * i}px !important;
      }

      .m__b--${2 * i} {
        margin-bottom: ${2 * i}px !important;
      }

      .m__l--${2 * i} {
        margin-left: ${2 * i}px !important;
      }

      .m__r--${2 * i} {
        margin-right: ${2 * i}px !important;
      }

      .t--${2 * i} {
        top: ${2 * i}px !important;
      }

      .l--${2 * i} {
        left: ${2 * i}px !important;
      }

      .r--${2 * i} {
        right: ${2 * i}px !important;
      }

      .b--${2 * i} {
        bottom: ${2 * i}px !important;
      }
    `);
  }

  return css`
    ${styles}
  `;
}

function createPaddingCss() {
  let styles = '';

  for (let i = 0; i < $maxPadding / 2; i++) {
    styles = styles.concat(`
      .p--${2 * i} {
        padding: ${2 * i}px !important;
      }

      .p__h--${2 * i} {
        padding-left: ${2 * i}px !important;
        padding-right: ${2 * i}px !important;
      }

      .p__v--${2 * i} {
        padding-top: ${2 * i}px !important;
        padding-bottom: ${2 * i}px !important;
      }

      .p__t--${2 * i} {
        padding-top: ${2 * i}px !important;
      }

      .p__b--${2 * i} {
        padding-bottom: ${2 * i}px !important;
      }

      .p__l--${2 * i} {
        padding-left: ${2 * i}px !important;
      }

      .p__r--${2 * i} {
        padding-right: ${2 * i}px !important;
      }
    `);
  }

  return css`
    ${styles}
  `;
}

const SpacingStyle = css`
  ${createMarginCss()}

  .m__v--auto {
    margin: auto 0 !important;
  }

  .m__h--auto {
    margin: 0 auto !important;
  }

  ${createPaddingCss()}

  .align-items-center {
    align-items: center !important;
  }

  .justify-center {
    justify-content: center !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-70 {
    width: 70% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .h-100 {
    height: 100% !important;
  }

  .h-auto {
    height: auto !important;
  }

  .max-width-100 {
    max-width: 100% !important;
  }

  .max-height-100 {
    max-height: 100% !important;
  }

  .m--auto {
    margin: auto;
  }

  .column-gap-10 {
    column-gap: 10px;
  }

  .m__l--auto {
    margin-left: auto;
  }

  .m__r--auto {
    margin-right: auto;
  }
`;

export default SpacingStyle;
