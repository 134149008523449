import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router-dom';
import { styled } from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';
import MainHeader from './Header';

const PageLayout = () => {
  return (
    <StyledLayout>
      <MainHeader />
      <Layout>
        <Sidebar />
        <Content className="bg--white">
          <Outlet />
        </Content>
      </Layout>
    </StyledLayout>
  );
};

export default PageLayout;

const StyledLayout = styled(Layout)`
  width: 100%;
  height: 100%;
  height: 100vh;
  max-height: 100vh;

  .ant-layout-sider {
    flex: 0 0 180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
    width: 180px !important;
  }

  /* overflow: auto; */
`;
