import {
  PaginationParamsRequest,
  PaginationParamsResponse,
} from '../interfaces/PaginationParamsResponse';
import {
  IUser,
  UserActivationRequest,
  UserActivationResponse,
  UserRegister,
} from '../interfaces/UserModel';
import Client from './client';

class UserApi {
  private static classInstance?: UserApi;

  public static get instance() {
    if (!this.classInstance) {
      this.classInstance = new UserApi();
    }
    return this.classInstance;
  }

  public listUsers = (
    pagination: PaginationParamsRequest
  ): Promise<PaginationParamsResponse<IUser>> => {
    const { page, perPage, all } = pagination;
    return Client.get(
      `/user?page=${page}&perPage=${perPage}&${all ? 'all=true' : ''}`
    );
  };

  public getUserInfo(id: string): Promise<IUser> {
    return Client.get(`/user/${id}`);
  }

  public createUser(body: IUser): Promise<IUser> {
    return Client.post(`/user`, body);
  }

  public updateUser(userId: string, body: IUser): Promise<IUser> {
    return Client.patch(`/user/${userId}/info`, body);
  }

  public register = (payload: UserRegister) =>
    Client.post<any>('/user', payload);

  public verifyEmail = (
    payload: UserActivationRequest
  ): Promise<UserActivationResponse> =>
    Client.post(`/user/${payload.id}/verify`, {
      token: payload.token,
    });

  public resendVerificationByEmail = (email: string) => {
    return Client.post<any>(`/user/verify/resend`, {
      email,
    });
  };

  public resendLinkedLocalVerificationByEmail = (email: string) => {
    return Client.post<any>(`/user/local/verify/resend`, {
      email,
    });
  };

  public getMe = (): Promise<IUser> => Client.get('/user/me');

  public forgotPassword = (email: string) => {
    return Client.post<any>('/user/forgot-password', { email });
  };

  public checkResetPasswordToken = (userId: string, token: string) => {
    return Client.get<any>(
      `/user/${userId}/reset-password/check?token=${token}`
    );
  };

  public updatePassword = (userId: string, newPassword: string) => {
    return Client.patch<any>(`/user/${userId}/password`, {
      newPassword,
    });
  };

  public validateUpdateEmail = (userId: string, token: string) => {
    return Client.post<any>('/user/email/update/validate', {
      userId,
      token,
    });
  };

  public resendValidateUpdateEmail = (userId: string) => {
    return Client.post<any>('/user/email/update/validate/resend', {
      userId,
    });
  };

  public updateUserAvatar = (body: FormData): Promise<any> => {
    return Client.post<any>('/user/avatar', body);
  };

  public validateUsername = (username: string): Promise<any> => {
    return Client.post<any>('/user/username/exists', {
      username: username.toLowerCase(),
    });
  };

  public deleteUserAvatar = () => {
    return Client.delete<any>('/user/avatar');
  };

  public emailRecovery = (email: string) => {
    return Client.post<any>('/user/email-recovery', { email });
  };

  public close = (id: string, password: string) => {
    return Client.post<any>(`/user/${id}/close`, { password });
  };
}

export default UserApi.instance;
