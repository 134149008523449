// import "antd/dist/antd.compact.css";
// import "antd/dist/antd.css";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import DesignList from './components/Design/DesignList';
import ImageProcess from './components/ImageProcess/ImageProcess';
import Layout from './components/Layout/Layout';
import OrderDetail from './components/Order/OrderDetail';
import OrderList from './components/Order/OrderList';
import SizeDetail from './components/Size/SizeDetail';
import SizeList from './components/Size/SizeList';
import Statistics from './components/Stats/Statistics';
import UserList from './components/User/User';
import UserDetail from './components/User/UserDetail';
import './index.css';
import Login from './pages/Login/Login';
import reportWebVitals from './reportWebVitals';
import { Role } from './role/role';
import PrivateRoute from './routes/PrivateRoute';
import RedirectRoutePerRole from './routes/RedirectRoutePerRole';
import { store } from './store';
import GlobalStyle from './styles/Global';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route element={<App />}>
          <Route path="login">
            <Route index element={<Login />} />
          </Route>
          <Route element={<Layout />}>
            <Route path="/" element={<RedirectRoutePerRole />} />
            <Route path="user">
              <Route
                index
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <UserList />
                  </PrivateRoute>
                }
              />
              <Route
                path="create"
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <UserDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path=":id/edit"
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <UserDetail />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="size">
              <Route
                index
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <SizeList />
                  </PrivateRoute>
                }
              />
              <Route
                path="create"
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <SizeDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path=":id/edit"
                element={
                  <PrivateRoute roles={[Role.ADMIN]}>
                    <SizeDetail />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="image"
              index
              element={
                <PrivateRoute roles={[Role.ADMIN]}>
                  <ImageProcess />
                </PrivateRoute>
              }
            />
            <Route path="order">
              <Route
                index
                element={
                  <PrivateRoute roles={[Role.ADMIN, Role.SELLER]}>
                    <OrderList />
                  </PrivateRoute>
                }
              />
              <Route
                path="create"
                element={
                  <PrivateRoute roles={[Role.ADMIN, Role.SELLER]}>
                    <OrderDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path=":id/edit"
                element={
                  <PrivateRoute roles={[Role.ADMIN, Role.SELLER]}>
                    <OrderDetail />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="design"
              element={
                <PrivateRoute roles={[Role.ADMIN, Role.SELLER]}>
                  <DesignList />
                </PrivateRoute>
              }
            />
            <Route
              path="stats"
              element={
                <PrivateRoute roles={[Role.ADMIN, Role.SELLER]}>
                  <Statistics />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
