import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import userApi from '../../apis/user';
import { getQueryParams } from '../../helpers/params';
import { PaginationParamsResponse } from '../../interfaces/PaginationParamsResponse';
import { IUser } from '../../interfaces/UserModel';
import { CustomTable, PageContainer } from '../../styles/Global';
import ChangePasswordModal from '../Modal/ChangePasswordModal';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const UserList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [itemsPagination, setItemsPagintion] = useState<
    PaginationParamsResponse<IUser>
  >({
    items: [],
    page: 1,
    perPage: 10,
    total: 0,
    pageCount: 0,
  });

  const columns = [
    {
      title: 'Full Name',
      key: 'size',
      editable: false,
      render: (info: IUser) => (
        <Link to={`/user/${info?.id}/edit`}>
          <p>{info?.name}</p>
        </Link>
      ),
    },
    {
      title: 'Username',
      key: 'username',
      editable: true,
      dataIndex: 'username',
    },
    {
      title: 'Email',
      key: 'email',
      editable: true,
      dataIndex: 'email',
    },
    {
      title: 'Role',
      key: 'role',
      editable: true,
      dataIndex: 'role',
    },
    {
      title: 'Action',
      key: 'action',
      editable: false,
      render: (info: IUser) => (
        <Button
          type="link"
          onClick={() => {
            setVisibleModal(true);
            setSelectedUser(info);
          }}
          disabled={!!info?.deletedAt}
        >
          Change password
        </Button>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const page = parseInt(getQueryParams().get('page') || '') || 1;
    const perPage = parseInt(getQueryParams().get('perPage') || '') || 10;

    loadData(page, perPage);
  }, [location]);

  const loadData = async (
    page = itemsPagination?.page,
    perPage = itemsPagination?.perPage
  ) => {
    try {
      const res = await userApi.listUsers({ page, perPage });

      setItemsPagintion(res);
    } catch (error) {
      setItemsPagintion({
        items: [],
        page,
        perPage,
        total: 0,
        pageCount: 0,
      });
      console.error(error);
    } finally {
    }
  };

  const onStateChanges = async (page: number, perPage: number) => {
    navigate(`${location.pathname}?page=${page}&perPage=${perPage}`, {
      replace: true,
    });
    loadData(page, perPage);
  };

  const onPageChange = (page: number, pageSize: number) => {
    onStateChanges(page, pageSize);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Manage User"
        addButton={
          <Link to="/user/create">
            <PrimaryButton className="font-weight--500" key="1">
              + Create Seller
            </PrimaryButton>
          </Link>
        }
      />

      <CustomTable
        className="m__t--20"
        columns={columns}
        dataSource={itemsPagination.items}
        pagination={{
          onChange: onPageChange,
          total: itemsPagination.total,
          pageSize: itemsPagination.perPage,
        }}
      />

      <ChangePasswordModal
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        user={selectedUser}
      />
    </PageContainer>
  );
};
export default UserList;
