import {
  CONNECT_SOCIAL_FAILURE,
  CONNECT_SOCIAL_SUCCESS,
  STORE_VALIDATE_EMAIL_INTERVAL,
  TOGGLE_WAITING_TO_VALIDATE_EMAIL,
  UPDATE_VALIDATE_EMAIL_COUNTER,
} from '../actions/user';

const RESEND_EMAIL_DURATION = 60;

const initialState = {
  validateEmailCounter: 0,
  waitingToValidateEmail: false,
  validateEmailInterval: null,
  connectSocialSuccessMessage: null,
  connectSocialErrorMessage: null,
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_VALIDATE_EMAIL_COUNTER: {
      return {
        ...state,
        validateEmailCounter:
          state.validateEmailCounter > 0 ? state.validateEmailCounter - 1 : 0,
      };
    }
    case TOGGLE_WAITING_TO_VALIDATE_EMAIL: {
      const newWaitingToValidateEmail = !state.waitingToValidateEmail;
      if (!newWaitingToValidateEmail && !!state.validateEmailInterval) {
        clearInterval(state.validateEmailInterval);
      }
      return {
        ...state,
        waitingToValidateEmail: newWaitingToValidateEmail,
        validateEmailCounter: newWaitingToValidateEmail
          ? RESEND_EMAIL_DURATION
          : 0,
      };
    }
    case STORE_VALIDATE_EMAIL_INTERVAL: {
      return {
        ...state,
        validateEmailInterval: action.interval,
      };
    }
    case CONNECT_SOCIAL_SUCCESS: {
      return {
        ...state,
        connectSocialSuccessMessage: action.message,
      };
    }
    case CONNECT_SOCIAL_FAILURE: {
      return {
        ...state,
        connectSocialErrorMessage: action.message,
      };
    }
    default:
      return state;
  }
};

export default user;
