import { css } from 'styled-components';
import {
  $black,
  $danger,
  $fontSizeLarge,
  $fontSizeMedium,
  $fontSizeNormal,
  $fontSizeSmall,
  $fontSizeXLarge,
  $fontSizeXXLarge,
  $fontSizeXsmall,
  $fontSizeXxsmall,
  $lightgrey,
  $primary,
  $purple,
  $secondary,
} from './Variables';

function createFontWeightCss() {
  let styles = '';

  for (let i = 1; i <= 9; i++) {
    styles = styles.concat(`
      .font-weight--${i * 100} {
        font-weight: ${i * 100} !important;
      }
    `);
  }

  return css`
    ${styles}
  `;
}

const GlobalFontStyle = css`
  .font-size {
    &--xxsmall {
      font-size: ${$fontSizeXxsmall} !important;
    }

    &--xsmall {
      font-size: ${$fontSizeXsmall} !important;
    }

    &--small {
      font-size: ${$fontSizeSmall} !important;
    }

    &--normal {
      font-size: ${$fontSizeNormal} !important;
    }

    &--medium {
      font-size: ${$fontSizeMedium} !important;
    }

    &--large {
      font-size: ${$fontSizeLarge} !important;
    }

    &--xlarge {
      font-size: ${$fontSizeXLarge} !important;
    }

    &--xxlarge {
      font-size: ${$fontSizeXXLarge} !important;
    }
  }

  .font-style {
    &--italic {
      font-style: italic;
    }
  }

  .text {
    &-primary {
      color: ${$primary} !important;
    }

    &-secondary {
      color: ${$secondary} !important;
    }

    &-danger {
      color: ${$danger} !important;
    }

    &-black {
      color: ${$black} !important;
    }

    &-purple {
      color: ${$purple} !important;
    }

    &-grey {
      color: ${$lightgrey} !important;
    }

    &-mistake {
      color: var(--color-mistake) !important;
    }

    &-white {
      color: #fff !important;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${createFontWeightCss()};
`;

export default GlobalFontStyle;
