/* eslint-disable prefer-spread */
import { css } from 'styled-components';
import { Breakpoints } from './Breakpoints';

/**
 * https://tobbelindstrom.com/blog/how-to-create-a-breakpoint-mixin-with-styled-components/
 */
export const mediaQuery = Object.keys(Breakpoints).reduce(
  (accumulator: Record<string, any>, label) => {
    accumulator[label] = (...args: any[]) => {
      const [first, ...rest] = args;
      return css`
        @media screen and (min-width: ${Breakpoints[label]}px) {
          ${css.apply(null, [first, ...rest])};
        }
      `;
    };
    return accumulator;
  },
  {}
);
