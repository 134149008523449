export enum OrderStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SHIPPED = 'shipped',
  RESHIPPED = 'reshipped',
  CANCELLED = 'cancelled',
}

export enum ResendRequestFilter {
  PENDING_RESEND = 'pending_resend',
  RESEND_APPROVED = 'resend_approved',
  RESEND_REJECTED = 'resend_rejected',
}

export enum Country {
  CANADA = 'ca',
  US = 'us',
}

export enum TrackingFilter {
  NoTracking = 'no_tracking',
  TrackingOnly = 'tracking_only',
}
