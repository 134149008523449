import { Dispatch } from "redux";
import authApi from "../apis/auth";
import { parseJwt } from "../helpers/jwt";
import { AuthActionTypeEnum } from "../interfaces/Reducer.interface";
import { IUser, UserInfo } from "../interfaces/UserModel";

export const setTokenLocalStorage = (
  accessToken: string,
  refreshToken: string
) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const removeTokenLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getTokenLocalStorage = () => {
  return {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };
};

export const login = (
  body: any,
  actionSuccess?: () => any,
  actionFailure?: any
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: AuthActionTypeEnum.LOGIN_REQUEST,
    });

    authApi
      .login(body)
      .then((res) => {
        const { accessToken, refreshToken } = res;

        setTokenLocalStorage(accessToken, refreshToken);
        const { sub, role } = parseJwt(accessToken);

        dispatch({
          type: AuthActionTypeEnum.LOGIN_SUCCESS,
          payload: {
            authUser: {
              id: sub,
              role,
            },
          },
        });

        dispatch(getMe());

        !!actionSuccess && actionSuccess();
      })
      .catch((error) => {
        dispatch({
          type: AuthActionTypeEnum.LOGIN_FAILURE,
          error,
        });

        !!actionFailure && actionFailure();
      });
  };
};

export const logout = (actionSuccess?: any, actionFailure?: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AuthActionTypeEnum.LOGOUT_REQUEST,
    });

    const { refreshToken } = getTokenLocalStorage();
    if (refreshToken) {
      authApi
        .logout(refreshToken as string)
        .then(() => {
          dispatch({
            type: AuthActionTypeEnum.LOGOUT_SUCCESS,
          });

          !!actionSuccess && actionSuccess();
        })
        .catch((error) => {
          dispatch({
            type: AuthActionTypeEnum.LOGOUT_FAILURE,
            error,
          });
          !!actionFailure && actionFailure();
        })
        .finally(() => {
          removeTokenLocalStorage();
        });
    }
  };
};

export const getMe = (actionSuccess?: any, actionFailure?: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AuthActionTypeEnum.GET_ME_REQUEST,
    });

    authApi
      .getMe()
      .then((res) => {
        dispatch({
          type: AuthActionTypeEnum.GET_ME_SUCCESS,
          payload: {
            currentUser: res,
          },
        });

        !!actionSuccess && actionSuccess();
      })
      .catch((error) => {
        dispatch({
          type: AuthActionTypeEnum.GET_ME_FAILURE,
          error,
        });
        !!actionFailure && actionFailure();
      });
  };
};

export const setCurrentUser =
  (payload: IUser | null) => (dispatch: Dispatch) => {
    dispatch({
      type: AuthActionTypeEnum.SET_CURRENT_USER,
      payload: {
        currentUser: payload,
      },
    });
  };

export const setAuthenticating = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: AuthActionTypeEnum.SET_AUTHENTICATING,
    payload: {
      authenticating: payload,
    },
  });
};
export const setUserScheduleSetting =
  (payload: UserInfo) => (dispatch: Dispatch) => {
    dispatch({
      type: AuthActionTypeEnum.SET_USER_SCHEDULE_SETTING,
      payload,
    });
  };

export const userLogout = () => (dispatch: Dispatch) => {
  dispatch({
    type: AuthActionTypeEnum.USER_LOGOUT,
    payload: {},
  });
};
