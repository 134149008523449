import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getMe, getTokenLocalStorage } from '../actions/auth';
import { RootState } from '../reducers';
import { IAuthState } from '../reducers/auth';

const RedirectRoutePerRole = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );
  const { accessToken } = getTokenLocalStorage();

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getMe());
    }
  }, [accessToken]);

  if (authUser) {
    const { role } = authUser;

    return <Navigate to="/order" replace />;
  }

  return <Navigate to="/login" />;
};

export default RedirectRoutePerRole;
