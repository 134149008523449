import { DatePicker } from 'antd';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import statsApi from '../../apis/stats';
import userApi from '../../apis/user';
import { OrderStatus } from '../../constants/order';
import { useHasMounted } from '../../hooks';
import { StatsResponse } from '../../interfaces/Stats';
import { RootState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { Role } from '../../role/role';
import { PageContainer } from '../../styles/Global';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {
  CardStats,
  CardStatsContainer,
  ChartContainer,
  ChartWrapper,
  Row,
  SearchFilterContainer,
  StyledSelect,
} from './Statistics.styled';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);

const Statistics = () => {
  const { authUser, currentUser } = useSelector(
    (state: RootState) => state.auth as IAuthState
  );
  const isAdmin = currentUser?.role === Role.ADMIN;
  const [status, setStatus] = useState<OrderStatus>();
  const [sortFieldValue, setSortFieldValue] = useState<string>('');
  const [sortOrderValue, setSortOrderValue] = useState<string>('');
  const [filterDate, setFilterDate] = useState<{
    startDate: any;
    endDate: any;
  }>();
  const [statsData, setStatsData] = useState<StatsResponse>({
    totalShippedOrders: 0,
    totalRevenue: 0,
    totalCost: 0,
    totalOrders: 0,
    totalCancelledOrders: 0,
    totalReshippedOrders: 0,
    totalReshippedOrdersCost: 0,
    totalRevenueByMonthsDataset: { data: [], labels: [] },
    totalCostByStatusDataset: { data: [], labels: [] },
    totalShippedOrdersByMonthsDataset: { data: [], labels: [] },
    totalOrdersByStatusDataset: { data: [], labels: [] },
  });
  const [userOption, setUserOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [username, setUsername] = useState('');
  const mounted = useHasMounted();

  useEffect(() => {
    if (!mounted() || !isAdmin) {
      return;
    }

    fetchUser();
    handleStats();
  }, [isAdmin, mounted]);

  const fetchUser = async () => {
    try {
      const res = await userApi.listUsers({ page: 1, perPage: 50, all: true });
      const tmp = res.items.map((user) => {
        return { label: user.name, value: user.username };
      });
      tmp.unshift({ label: 'View All', value: '' });
      setUserOption(tmp);
    } catch (error) {
      setUserOption([]);
      console.error(error);
    } finally {
    }
  };

  const handleStats = async () => {
    try {
      if (!isAdmin && !currentUser?.username) return;

      let query = {
        fromDate: filterDate?.startDate,
        toDate: filterDate?.endDate,
      };
      const res = !isAdmin
        ? await statsApi.stats(query)
        : !!username
        ? await statsApi.userStats(username, query)
        : await statsApi.stats(query);
      setStatsData(res);
    } catch (error) {}
  };

  return (
    <PageContainer>
      <PageHeader title="Statistics" />
      <SearchFilterContainer $isAdmin={isAdmin}>
        <DatePicker.RangePicker
          onChange={(val) => {
            if (Array.isArray(val) && val.length > 1) {
              setFilterDate({
                startDate: dayjs.default(val[0]).toISOString(),
                endDate: dayjs.default(val[1]).toISOString(),
              });
            } else {
              setFilterDate({
                startDate: null,
                endDate: null,
              });
            }
          }}
          allowClear
        />
        {isAdmin && (
          <StyledSelect
            className="w-100"
            placeholder="Choose user"
            onChange={(val: any) => {
              setUsername(val);
            }}
            options={userOption}
          />
        )}
        <PrimaryButton
          className="font-weight--500"
          key="1"
          onClick={handleStats}
        >
          Apply
        </PrimaryButton>
      </SearchFilterContainer>
      <div>
        <CardStatsContainer>
          <CardStats title="Total Orders" value={statsData.totalOrders} />
          <CardStats
            title="Total Reshipped Orders"
            value={statsData.totalReshippedOrders}
          />
          <CardStats
            title="Total Refunded Orders"
            value={statsData.totalCancelledOrders}
          />
          <CardStats title="Total Cost" value={statsData.totalCost} />
        </CardStatsContainer>

        <Row>
          <ChartWrapper>
            <p>Stats Order By Status</p>
            <ChartContainer>
              <Pie
                height="200px"
                width="200px"
                options={{ maintainAspectRatio: false }}
                data={{
                  labels: statsData.totalOrdersByStatusDataset.labels,
                  datasets: [
                    {
                      label: '# Total',
                      data: statsData.totalOrdersByStatusDataset.data || [],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </ChartContainer>
          </ChartWrapper>
          <ChartWrapper>
            <p>Stats Cost By Status</p>
            <ChartContainer>
              <Pie
                height="200px"
                width="200px"
                options={{ maintainAspectRatio: false }}
                data={{
                  labels: statsData.totalCostByStatusDataset.labels,
                  datasets: [
                    {
                      label: '# Total',
                      data: statsData.totalCostByStatusDataset.data || [],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </ChartContainer>
          </ChartWrapper>
        </Row>
      </div>
    </PageContainer>
  );
};

export default Statistics;
