import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import orderApi from '../../apis/order';
import { getQueryParams } from '../../helpers/params';
import { ISize } from '../../interfaces/Order';
import { PaginationParamsResponse } from '../../interfaces/PaginationParamsResponse';
import { CustomTable, PageContainer } from '../../styles/Global';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const SizeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [itemsPagination, setItemsPagintion] = useState<
    PaginationParamsResponse<ISize>
  >({
    items: [],
    page: 1,
    perPage: 10,
    total: 0,
    pageCount: 0,
  });

  const columns = [
    {
      title: 'Size Name',
      key: 'size',
      editable: false,
      render: (info: any) => (
        <Link to={`/size/${info?.id}/edit`}>
          <p>{info?.size}</p>
        </Link>
      ),
    },
    {
      title: 'Product',
      key: 'product',
      editable: false,
      dataIndex: 'product',
    },
    {
      title: 'Price',
      key: 'price',
      editable: false,
      dataIndex: 'price',
    },
    {
      title: 'Description',
      key: 'description',
      editable: false,
      dataIndex: 'description',
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const page = parseInt(getQueryParams().get('page') || '') || 1;
    const perPage = parseInt(getQueryParams().get('perPage') || '') || 10;

    loadData(page, perPage);
  }, [location]);

  const loadData = async (
    page = itemsPagination?.page,
    perPage = itemsPagination?.perPage
  ) => {
    try {
      const res = await orderApi.getSizes({ page, perPage });

      setItemsPagintion(res);
    } catch (error) {
      setItemsPagintion({
        items: [],
        page,
        perPage,
        total: 0,
        pageCount: 0,
      });
      console.error(error);
    } finally {
    }
  };

  const onStateChanges = async (page: number, perPage: number) => {
    navigate(`${location.pathname}?page=${page}&perPage=${perPage}`, {
      replace: true,
    });
    loadData(page, perPage);
  };

  const onPageChange = (page: number, pageSize: number) => {
    onStateChanges(page, pageSize);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Manage Size"
        addButton={
          <Link to="/size/create">
            <PrimaryButton className="font-weight--500" key="1">
              + Create Size
            </PrimaryButton>
          </Link>
        }
      />

      <CustomTable
        className="m__t--20"
        columns={columns}
        dataSource={itemsPagination.items}
        pagination={{
          onChange: onPageChange,
          total: itemsPagination.total,
          pageSize: itemsPagination.perPage,
        }}
      />
    </PageContainer>
  );
};
export default SizeList;
