import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import userApi from '../../apis/user';
import { ISize } from '../../interfaces/Order';
import { IUser } from '../../interfaces/UserModel';
import { Role } from '../../role/role';
import { ButtonGroupContainer, PageContainer } from '../../styles/Global';
import { $background } from '../../styles/Variables';
import CustomForm from '../Form/Form';
import CustomInput from '../Form/FormItem/Input/Input';
import PageHeader from '../PageHeader/PageHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

export interface SizeForm extends ISize {
  publishedDateISO?: Date;
  startedAtISO?: Date;
  endedAtISO?: Date;
  bannerImage?: any;
  horizontalCoverImage?: any;
  horizontalCoverV2Image?: any;
  playlistLinksItems?: {
    playlistLinks: string;
  }[];
}

const ActionsStyled = styled.div`
  border-radius: 8px;
  background-color: ${$background};
  padding: 20px;
  width: 164px;
  position: sticky;
  top: 90px;
`;

const ActionsSyncStyled = styled(ActionsStyled)`
  top: 200px;

  .ant-btn-loading {
    &::before {
      display: none;
    }
  }
`;

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const isCreating = !id && !loading;
  const [form] = Form.useForm<IUser>();

  const [user, setUser] = useState<IUser>({
    name: '',
    username: '',
    email: '',
    role: Role.SELLER,
  });

  const fetchUser = async (id: string) => {
    try {
      const result = await userApi.getUserInfo(id);
      setLoading(true);
      setUser(result);
      form.setFieldsValue({
        name: result.name,
        email: result.email,
        username: result.username,
        role: result.role,
      });
    } catch (error) {
      navigate(-1);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!id) {
      fetchUser(id);
    }
  }, []);

  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);

  const updateUser = async () => {
    if (!id) {
      return;
    }

    try {
      setLoading(true);
      const { username, name, email } = form.getFieldsValue(true);

      const payload: IUser = {
        username,
        name,
        email,
      };

      await userApi.updateUser(id, payload);
      message.success('Update user success!');
      navigate(-1);
    } catch (e) {
      console.error(e);
      message.error('Update user failed');
    } finally {
      setLoading(false);
    }
  };

  const createUser = async () => {
    try {
      setLoading(true);
      const payload: IUser = form.getFieldsValue(true);

      await userApi.createUser(payload);
      message.success('Create user success!');
      navigate(-1);
    } catch (e) {
      console.error(e);
      message.error('Create user failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageContainer>
        <PageHeader title="User Detail" showBackButton />
        <div className="w-70">
          {/* <CustomPageHeader
            className="site-page-header p--0"
            title={id ? programInfoData.name : "New program"}
            onBack={() => navigate(-1)}
          /> */}

          <CustomForm
            form={form}
            initialValues={user}
            layout="vertical"
            scrollToFirstError
            requiredMark="optional"
            onFinish={() => {
              if (!id) {
                createUser();
                return;
              }

              updateUser();
            }}
          >
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input username',
                },
              ]}
            >
              <CustomInput placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="name"
              label="Full name"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input full name',
                },
              ]}
            >
              <CustomInput placeholder="Full name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input a valid email',
                },
              ]}
            >
              <CustomInput placeholder="Email" />
            </Form.Item>
            {isCreating && (
              <>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message:
                        'Please input a password with at least 8 characters including numbers and alphabeltical characters',
                    },
                  ]}
                >
                  <CustomInput placeholder="Password" type="password" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <CustomInput placeholder="Confirm password" type="password" />
                </Form.Item>
              </>
            )}

            <Form.Item>
              <ButtonGroupContainer>
                <PrimaryButton htmlType="submit">
                  {isCreating ? 'Create' : 'Save'}
                </PrimaryButton>
              </ButtonGroupContainer>
            </Form.Item>
          </CustomForm>
        </div>

        {!!id && (
          <div className="w-25 p__h--90">
            <ActionsStyled>
              <Button
                className="w-100 font-weight--300 bg--transparent border--none text-danger text-align--left p__v--4 p__h--11"
                icon={<DeleteOutlined className="font-size--16 p__r--8" />}
                type="text"
              >
                <span className="m__l--10 font-size--14">Remove</span>
              </Button>
            </ActionsStyled>
          </div>
        )}
      </PageContainer>
    </>
  );
};

export default UserDetail;
